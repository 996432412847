import React from 'react';
import classNames from 'classnames';
import { unescape } from 'he';
import { LEFT_NAV } from 'storefront/components/constants';
import { referralSourceClicked } from 'client/common/actions/actions-page';
import LeftNavSubTree from '../LeftNavSubTree/leftNavSubTree';
import CustomLink from 'client/common/components/CustomLink/customLink';

const setPrevPageCookie = (name) => {
  referralSourceClicked(name, LEFT_NAV);
};

const LeftNavTreeNode = ({ node }) => {
  const {
    id, name = '', url, categories, selected, redText,
  } = node;
  return (
    <li>
      <CustomLink
        id={`leftNav-${id}`}
        url={url}
        className={classNames({
          'left-nav__category red-text': redText,
          'left-nav__category active': selected,
        })}
        role={selected ? "tab" : undefined}
        aria-selected={selected}
        onClick={() => setPrevPageCookie(unescape(name))}
      >
        { name && unescape(name.trim()) }
      </CustomLink>
      <LeftNavSubTree
        subTree={categories}
      />
    </li>
  );
};

export default LeftNavTreeNode;
