import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import take from 'lodash/take';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import uniqBy from 'lodash/uniqBy';
import classnames from 'classnames';
import './SearchSuggestions.scss';
import TypeaheadResults from 'client/storefront/components/Header/Search/TypeaheadResults/typeaheadResults';
import Image from 'client/pdp/components/Image/image';
import {
  TOP_ITEMS,
  SEARCH_ITEMS,
  CATEGORY_ITEMS,
  topItemsData,
  searchData,
  categoryData,
} from './suggestConstants';
import { saveToLocalStorage } from 'client-utils/utilities-storage';
import CustomLink from 'client/common/components/CustomLink/customLink';

const clearAllRecentSearch = (closeRecentSearchOnClear, e, storageKey) => {
  e.preventDefault();
  e.stopPropagation();
  if (typeof localStorage !== 'undefined') {
    saveToLocalStorage(storageKey, null);
  }
  closeRecentSearchOnClear();
};

const getAllRecentSearch = (recentSearchStorageKey) => {
  if (typeof localStorage !== 'undefined' && !isEmpty(localStorage)) {
    const parsedRecentSearches = JSON.parse(
      localStorage.getItem(recentSearchStorageKey)
    );
    const uniqueSearches = uniqBy(parsedRecentSearches?.filter((word) => word), (item) => {
      return item?.toLowerCase();
    });
    return take(uniqueSearches, 7);
  }
  return [];
};

const handleItemClick = (e, handleSelection, searchItem, onListClick) => {
  e.preventDefault();
  e.stopPropagation();

  onListClick();
  window.sessionStorage.setItem('recently_searched', true);
  handleSelection(searchItem);
  window.location.href = `/s/?from=brSearch&responsive=true&request_type=search&search_type=keyword&q=${searchItem}&l=${searchItem}&fl&src=suggest&recent=true`;
};

export const SearchSuggestions = ({
  closeRecentSearchOnClear,
  mobileView = false,
  forwardRef = null,
  handleSelection,
  arrowIndex,
  typeaheadData = {},
  typeaheadSuggestionPayload = {},
  searchTerm,
  noResults,
  topItems,
  setTypeaheadUtag,
  storageKey,
  onListClick,
  isGenderTypeahead,
  brand,
  collapsedSearchExperience,
}) => {
  const {
    suggestions: typeaheadSuggestions = [],
    rid,
    urlPrefix,
    serverQuery:
     typeAheadQuery,
    narrowedWithin: typeaheadInCategories = [],
    handleClickSuggestion,
    products: brSuggestionProducts = [],
  } = typeaheadSuggestionPayload;

  if (mobileView) {
    useEffect(() => {
      document.body.classList.add('body__scroll-disabled');
      return () => {
        document.body.classList.remove('body__scroll-disabled');
      };
    });
  }

  const recentSearch = getAllRecentSearch(storageKey);

  const formatPrice = (price) => {
    if (price) {
      if (!Number.isNaN(price)) {
        const priceValue = +price;

        return !Number.isInteger(priceValue) ? Number(priceValue).toFixed(2) : priceValue;
      }

      return price;
    }

    return 0;
  };

  const {
    popularDesignersList = [],
    trendingSearchesList = [],
    trt = 'Top Results',
    ict = 'In Categories',
  } = typeaheadData;

  const setUtag = (e, type) => {
    switch (type) {
      case TOP_ITEMS:
        setTypeaheadUtag(topItemsData);
        break;
      case SEARCH_ITEMS:
        setTypeaheadUtag(searchData);
        break;
      case CATEGORY_ITEMS:
        setTypeaheadUtag(categoryData);
        break;
      default:
        break;
    }
  };

  let suggestionsList = typeaheadSuggestions.length !== 0 && (
    <div className="suggestions-list">
      <div className="suggestions-items">
        <TypeaheadResults
          items={typeaheadSuggestions}
          rID={rid}
          urlPrefix={urlPrefix}
          query={typeAheadQuery}
          handleClickSuggestion={(item) => {
            onListClick();
            handleClickSuggestion(item);
          }}
          setUtag={setUtag}
          searchItems={SEARCH_ITEMS}
        />
      </div>
    </div>
  );

  if (noResults && searchTerm) {
    suggestionsList = brand !== 'HC' ? null : (
      <div className={classnames("designers-list", { 'text-align': collapsedSearchExperience })}>
        <h1 className="title-text">{`We weren't able to find any results for "${searchTerm}".`}</h1>
      </div>
    );
  }


  const recentSearches = () => (
    <div className={classnames("recent-searches", { 'align-recent-search': collapsedSearchExperience })}>
      <div className={classnames("recent-search-title-control", {'font-transform': collapsedSearchExperience })}>
        <div className="recent-search-title">
          <span className="title-text">Recent Searches</span>
        </div>
      </div>
      <div className="recent-searchItems">
        <ul>
          {recentSearch.map(
            (searchItem, index) => !isEmpty(searchItem) && (
            <li
              key={searchItem}
              className={classnames({
                'hightlight-arrow-updown': arrowIndex === index,
              })}
              onClick={(e) => {
                setUtag(e, SEARCH_ITEMS);
                handleItemClick(
                  e,
                  handleSelection,
                  startCase(camelCase(searchItem)),
                  onListClick
                );
              }}
              onTouchEnd={(e) => {
                setUtag(e, SEARCH_ITEMS);
                handleItemClick(
                  e,
                  handleSelection,
                  startCase(camelCase(searchItem)),
                  onListClick
                );
              }}
            >
              <button>{startCase(camelCase(searchItem))}</button>
            </li>
            )
          )}
        </ul>
      </div>

      {
        !searchTerm
        && (
        <div className="recent-search-control">
          <button
            className={classnames('clear-all-btn', {
              'no-background': mobileView,
            })}
            onClick={(e) => {
              clearAllRecentSearch(closeRecentSearchOnClear, e, storageKey);
            }}
            onTouchEnd={(e) => {
              clearAllRecentSearch(closeRecentSearchOnClear, e, storageKey);
            }}
          >
            {collapsedSearchExperience ? 'Clear all' :'Clear History'}
          </button>
        </div>
        )
      }
    </div>
  );

  const renderRightList = (list, listName) => {
    return brand !== 'HC' && (
      <div className="designers-list">
        <h1 className="title-text">{listName}</h1>
        <ul>
          {list.map((item, index) => {
            const linkContent = item.title || item.parsedFilterName || 'No title found';
            const linkPrefix = listName === ict ? 'in ' : '';
            return (
              <li
                key={index}
                onClick={(e) => {
                  setUtag(e, listName === 'In Categories' ? CATEGORY_ITEMS : SEARCH_ITEMS);
                  onListClick();
                }}
              >
                <CustomLink url={`${item.url}&src=suggest`}>{linkPrefix + linkContent}</CustomLink>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const topItem = (item, index) => (
    <div key={index} className="suggestion">
      <CustomLink
        url={`${item.url}${item.url?.indexOf('?') > -1 ? '&' : '?'}squery=${searchTerm}&src=suggest`}
        onClick={(e) => {
          setUtag(e, TOP_ITEMS);
          onListClick();
        }}
      >
        <Image src={item.img} alt={item.alt} className="suggest-img" useCloudinaryImages />
        <h2 className="item-title" dangerouslySetInnerHTML={{__html: item.brand || item.title}}></h2>
        <div>
          {item.oldPrice && !item.onePrice && (
            <span className="old-price">
$
              {formatPrice(item.oldPrice)}
            </span>
          )}
          {item.price && !item.onePrice && (
            <span
              className={`price ${
                item.oldPrice && item.price !== item.oldPrice
                  ? 'discount'
                  : ''
              }`}
            >
              $
              {formatPrice(item.price)}
            </span>
          )}
          {item.onePrice && (
            <span className="one-price">
$
              {formatPrice(item.onePrice)}
            </span>
          )}
        </div>
      </CustomLink>
    </div>
  );

  const renderTopItems = (list, listName) => (
    <div className="top-items">
      <h1 className="title-text">{listName}</h1>
      <div className={`products ${list.length === 1 ? 'single-product' : ''}`}>
        {list.map((item, index) => {
          if (list.length >= 4 && index === 1) {
            return [topItem(item, index), <div className="breakline" />];
          }
          return topItem(item, index);
        })}
      </div>
    </div>
  );

  const renderMobileCategories = (list, listName) => {
    return (list.length > 0
      && (
      <div className="row mobileView mobile-categories-list">
        <ul>
          {list.map((item, index) => {
            const linkContent = item.title || item.parsedFilterName || 'No title found';
            const linkPrefix = listName === ict ? 'in ' : '';
            return (
              <li key={index}>
                <CustomLink url={item.url || ''}>{`${linkPrefix}${linkContent}`}</CustomLink>
              </li>
            );
          })}
        </ul>
      </div>
      )
    );
  };

  const showTopItems = !isEmpty(topItems) && typeof topItems[0] !== 'string' && typeaheadSuggestions.length !== 0;
  return brand !== 'HC' ? (
    <div
      className={classnames('search-suggestions-frame', {
        typeahead__frame: !mobileView,
        'mobile-view__frame': mobileView,
        'typeahead-position': isGenderTypeahead,
        'new-suggestions-frame': (mobileView && collapsedSearchExperience)
      })}
      ref={forwardRef}
    >

      {
        searchTerm ? 
        <div className={`row ${mobileView ? 'mobileView' : ''}`}>{suggestionsList}</div> 
        : recentSearch.length > 0 && <div className={`row ${mobileView ? 'mobileView' : ''}`}>{recentSearches()}</div>
      }

      {
        searchTerm && isGenderTypeahead && (brSuggestionProducts.length !== 0
        ? renderTopItems(brSuggestionProducts.slice(0, mobileView ? 4 : 3), trt) 
        : showTopItems && renderTopItems(topItems.slice(0, mobileView ? 4 : 3), 'Top Items'))
      }
    </div>
  )
    : searchTerm === '' && recentSearch.length !== 0 && (
    <div
      className={classnames('search-suggestions-frame', {
        typeahead__frame: !mobileView,
        'mobile-view__frame': mobileView,
        'typeahead-position': isGenderTypeahead,
      })}
      ref={forwardRef}
    >
      { mobileView && renderMobileCategories(typeaheadInCategories.slice(0, 2), ict)}
      <div className={`row ${mobileView ? 'mobileView withDivider' : ''}`}>
        {(recentSearch.length > 0 && !searchTerm) && recentSearches()}

        {searchTerm && suggestionsList}

        {!(recentSearch.length > 0 || searchTerm) && renderRightList(trendingSearchesList, 'Trending Searches')}
        {
          !mobileView
          && (
            typeaheadInCategories.length !== 0
              ? renderRightList(typeaheadInCategories, ict)
              : renderRightList(popularDesignersList, 'Popular Designers')
          )
        }
      </div>
      {
        brSuggestionProducts.length !== 0
          ? renderTopItems(brSuggestionProducts.slice(0, mobileView ? 4 : 3), trt)
          : showTopItems && renderTopItems(topItems.slice(0, mobileView ? 4 : 3), 'Top Items')
      }
    </div>
    );
};
