/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { unescape } from 'he';
import classnames from 'classnames';
import { getParams, updateParams } from 'client-utils/utilities-router';
import { setRecentlySearchedAnalytics } from 'client/storefront/components/Header/Search/search-actions';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router';
import './scrollMenu.scss';


export const ScrollMenuItem = ({
  id, text, link, selected,
}) => {
  return (
    <li key={id + text}>
      <Link to={link} >
        <span className={classnames({
          'selected-underline': selected,
        })}
        >
          {unescape(text.trim())}
        </span>
      </Link>
    </li>
  );
};

export const ScrollMenu = ({
  items,
  className,
  selectedCategory,
}) => {
  return (
    <nav className={className}>
      <ul>
        {items.map(({
          id, name, url,
        }) => {
          const selected = id === selectedCategory.id;
          return (
            <ScrollMenuItem
              key={id}
              text={name}
              link={url}
              selected={selected}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export const SRPScrollMenuItem = ({
  text, crumb, onClickHandler, isSelected,
}) => {
  const className = isSelected ? 'selected' : '';

  const linkElement = (
    <li className={className} onClick={() => onClickHandler(crumb)}>
      {unescape(text.trim())}
    </li>
  );
  return linkElement;
};


export class SRPScrollMenu extends React.Component {
  constructor() {
    super();

    this.state = {
      selected: false,
    };

    this.filterSubmit = this.filterSubmit.bind(this);
    this.getProductsForSelectedCategory = this.getProductsForSelectedCategory.bind(this);
    this.getInitialFilters = this.getInitialFilters.bind(this);
    this.getExistingFilters = this.getExistingFilters.bind(this);
    this.updateParamsForFilters = this.updateParamsForFilters.bind(this);
    this.scrollMenu = React.createRef();
  }

  componentDidUpdate() {
    this.scrollMenu.current.scrollLeft = 0;
  }

  getProductsForSelectedCategory(selectedCategoryCrumb) {
    const { setRecentlySearchedAnalytics } = this.props;
    setRecentlySearchedAnalytics(false);


    const selectedCategory = selectedCategoryCrumb.charAt(0) === '/'
      ? selectedCategoryCrumb.substr(1, selectedCategoryCrumb.length - 1)
      : selectedCategoryCrumb;
    const filterOptions = { Category: selectedCategory.split('/') };
    const existingFilters = this.getExistingFilters();

    const filters = Object.assign(this.getInitialFilters(),
      existingFilters, filterOptions);
    this.updateParamsForFilters(filters);
  }

  getInitialFilters() {
    let result = {};

    if (this.props.applicableFilters) {
      result = this.props.applicableFilters.reduce((result, filter) => {
        return Object.assign({}, result, { [filter.filterKey]: [] });
      }, {});
    }

    return result;
  }

  getExistingFilters() {
    const { filterOptions: existingFiltersString } = getParams(this.props.router.location);
    return isEmpty(existingFiltersString) ? {} : JSON.parse(existingFiltersString);
  }

  updateParamsForFilters(updatedFilters) {
    const { router = {} } = this.props;
    updateParams(router, { filterOptions: JSON.stringify(updatedFilters), page: 1 });
  }

  filterSubmit(crumb) {
    this.getProductsForSelectedCategory(crumb);
    this.setState({ selected: crumb });
  }


  render() {
    const { className, items, selectedCategory } = this.props;

    return (
      <nav className={className}>
        <ul ref={this.scrollMenu}>
          {items.map(({ cat_name, crumb }, idx) => {
            const isSelected = selectedCategory === crumb;
            return (
              <SRPScrollMenuItem
                key={idx}
                text={cat_name}
                crumb={crumb}
                isSelected={isSelected}
                onClickHandler={this.filterSubmit}
              />
            );
          })}
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.srp.search.currentCategoryCrumb,
});

const mapDispatchToProps = {
  setRecentlySearchedAnalytics,
};

export default connect(mapStateToProps, mapDispatchToProps)(SRPScrollMenu);
