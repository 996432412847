import React, { Fragment, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import favIconActive from 'assets/images/favorite-active.svg';
import giftFinderIcon from 'assets/images/gift/Sparkle_GiftFinder_Paths.svg';
import BrandLogo from '../BrandLogo';
import SearchBoxDesktop from '../Search/SearchBox/searchBox';
import SearchBox from '../Search/SearchBox/newSearchBox';
import SearchBoxMobile from '../Search/SearchBoxMobile/searchBoxMobile';
import MiniCart from '../MiniCart/miniCart';
import { ConnectedSignInRegister, ConnectedYourAccount } from '../AccountHeaderMenuItem/AccountHeaderMenuItem';
import MobileNav from '../../Navigation/MobileNav/MobileNav';
import YourNeimansCTA from '../YourNeimansCTA/YourNeimansCTA';
import GenderSelect from '../GenderSelect/genderSelect';
import './cleanHeader.scss';
import CollapsibleSearchBar from '../Search/SearchBox/collapsibleSearchBar';
import CollapseSearchBarMobile from '../Search/SearchBoxMobile/collapseSearchBarMobile';
import CustomLink from 'client/common/components/CustomLink/customLink';

const CleanHeader = ({
  isMobilePhone,
  toggleSearchDisplay,
  onGenderSelected,
  hasOpenedSearch,
  isSearchExposed,
  tickerComponent,
  isStickyActive,
  addFocus,
  pageId,
  isTop,
  user,
  screenSizeDesktopInMobile,
  panelToggle,
  specialOffersPrompt,
  giftFinderToggle,
  holidayLogoToggle,
  brand,
  showGenderToggle,
  selectedGender,
  typeAheadExperience,
  isMenDarkMode,
  isDomestic,
  isAuthenticated,
  numNotifications,
  isImprovedPanelToggle,
  hcAssistanceToggle,
  globalNavUpdateToggle,
  offersData,
  isHNRightToggle,
  isCollapsedSearchBarToggle
}) => {
  const [isModalOpen, SetIsModalOpen] = useState(false);
  const renderStickyHeader = isMobilePhone && isStickyActive;
  const authenticated = user
    && user.securityStatus
    && (user.securityStatus.toLowerCase() === 'authenticated' || user.securityStatus.toLowerCase() === 'anonymous')
    && user.name
    && isAuthenticated;

  const minimalGlobalNav = Boolean(globalNavUpdateToggle);
  const renderCollapsibleSearchBar = (!isMobilePhone || screenSizeDesktopInMobile) 
    && isCollapsedSearchBarToggle && isDomestic;
  const renderMobileCollapseSearch = isMobilePhone && (isCollapsedSearchBarToggle && isDomestic); 

  const AccountHeaderMenuItem = authenticated
    ? <ConnectedYourAccount firstName={user.name} isFullWidth />
    : <ConnectedSignInRegister isFullWidth />;

  const brandLogo = isMobilePhone ? (
    <span className={
      classnames('brand-logo-clean-header-mobile',
        {
          'shift-right': renderStickyHeader,
        }, {
        'logo-shift-left': renderStickyHeader && isHNRightToggle
      })}
    >
      <BrandLogo holidayLogoToggle={holidayLogoToggle} brand={brand} />
    </span>
  )
    : <span className={classnames("brand-logo-clean-header", { "new-searchbar-center": renderCollapsibleSearchBar })}><BrandLogo holidayLogoToggle={holidayLogoToggle} brand={brand} /></span>;

  const favoriteIcon = !isMobilePhone && (
    <div className="favorite">
      {brand === 'HC'
        ? (
          <li className="your-neimans-cta">
            <CustomLink
              url="/account/account.jsp"
              className="your_account_hc"
            >
              YOUR ACCOUNT
            </CustomLink>
          </li>
        )
        : (
          <CustomLink url="/myfavorites/myFavoriteItems.jsp?view=favItems&forOmniLN=1&favin=1">
            <img alt="my favorite icon" src={favIconActive} className="favo-icon" />
          </CustomLink>
        )
      }
    </div>
  );

  const showCTA = isImprovedPanelToggle ? isAuthenticated !== null : true;

  const iconWrapper = renderStickyHeader
    ? (
      <div className={classnames(
        'sticky-header-icon-wrapper',
        isHNRightToggle && 'wrapper-icon'
      )}>
        {
          (renderMobileCollapseSearch && !typeAheadExperience)
          && (
            <button
              name="search-icon"
              title="search icon"
              aria-label="Search"
              className={classnames(
                'sticky-header-search-icon align-search-icon'
              )}
              onClick={(e) => openSearchModal(e)}
            />
          )
        }
        {panelToggle
          && showCTA && (
            <YourNeimansCTA
              brand={brand}
              firstName={authenticated && user.name}
              isMobilePhone={isMobilePhone}
              numNotifications={numNotifications}
              hcAssistanceToggle={hcAssistanceToggle}
              specialOffersPrompt={specialOffersPrompt}
              minimalGlobalNav={minimalGlobalNav}
              offersData={offersData}
              isHNRightToggle={isHNRightToggle}
            />
        )
        }
        {
        renderMobileCollapseSearch ? null : !isTop
          && (
            <button
              name="search-icon"
              title="search icon"
              aria-label="Search"
              className={classnames(
                'sticky-header-search-icon',
                isHNRightToggle && 'align-search-icon'
              )}
              onClick={toggleSearchDisplay}
            />
          )
        }
        <MiniCart />
      </div>
    )
    : <MiniCart />;

  const searchBoxMobileContainer = (isSearchExposed) => (
    <div className={isSearchExposed ? '' : 'hide-search-box'}>
      <SearchBoxMobile
        isSearchExposed={isSearchExposed}
        hasOpenedSearch={hasOpenedSearch}
        addFocus={addFocus}
      />
    </div>
  );

  const collapsibleSearchContainer = () => {
    return (
      <CollapsibleSearchBar brand={brand}/>
    );
  }

  const openSearchModal = (e) => {
    e.preventDefault();
    SetIsModalOpen(true);
  }

  const searchBoxComponent = () => {
    if(renderCollapsibleSearchBar){
      return collapsibleSearchContainer()
    } 

    return typeAheadExperience ? 
      <SearchBox /> : 
      <SearchBoxDesktop isMenDarkMode={isMenDarkMode} />
  }

  return (
    <Fragment>
      <div
        className={classnames({
          'sticky-header': isMobilePhone,
          active: renderStickyHeader,
          shadow:
            !isTop && (pageId !== 'PAGE_ID_PLP' && pageId !== 'PAGE_ID_SRP'),
          'dark-header': isMenDarkMode,
        })}
      >
        {isTop && renderStickyHeader && tickerComponent}
        <div id="clean-header-section" className="clean-header-container">
          <div
            className={classnames(
              'clean-header-section__left-aligned desktop-tablet-only',
              {
                'clean-header-sec-gobal-nav': isDomestic && minimalGlobalNav,
                'new-searchbar-align': renderCollapsibleSearchBar
              }
            )}
          >
            {brand !== 'HC' && showGenderToggle && (
              <GenderSelect
                onGenderSelected={(gender) => onGenderSelected(gender)}
                selectedGender={selectedGender}
              />
            )}
          </div>
          {giftFinderToggle && (
            <CustomLink url="/quiz/gift-finder" className="hide-on-mobile">
              <img src={giftFinderIcon} className="gift-finder-icon" />
            </CustomLink>
          )}
          <MobileNav />
          {brandLogo}
          <div className={classnames(
            'clean-header-section__right-aligned',
            {'collapsible-search-bar': renderCollapsibleSearchBar},
            {'menu-shift-left': (isMobilePhone && isHNRightToggle) },
          )}>
            <div className="clean-header-utility-menu">
              {isDomestic
                && (panelToggle
                  ? !isMobilePhone
                    && showCTA && (
                      <YourNeimansCTA
                        brand={brand}
                        firstName={authenticated && user.name}
                        isMobilePhone={isMobilePhone}
                        numNotifications={numNotifications}
                        hcAssistanceToggle={hcAssistanceToggle}
                        specialOffersPrompt={specialOffersPrompt}
                        minimalGlobalNav={minimalGlobalNav}
                        offersData={offersData}
                      />
                  )
                  : !isMobilePhone && AccountHeaderMenuItem)}
              {isDomestic && favoriteIcon}
              {pageId !== 'PAGE_ID_INTL_CART' && iconWrapper}
            </div>
            {(!isMobilePhone || screenSizeDesktopInMobile) && searchBoxComponent()}
          </div>
        </div>
        {!renderMobileCollapseSearch && (searchBoxMobileContainer(isSearchExposed))}
        {isModalOpen && (<CollapseSearchBarMobile
          isCollapsedSearchBarToggle={renderMobileCollapseSearch}
          closeModal={SetIsModalOpen}
        />)}
      </div>
    </Fragment>
  );
};

CleanHeader.propTypes = {
  isMobilePhone: PropTypes.bool,
  toggleSearchDisplay: PropTypes.func,
  onGenderSelected: PropTypes.func,
  hasOpenedSearch: PropTypes.bool,
  isSearchExposed: PropTypes.bool,
  tickerComponent: PropTypes.shape({ current: PropTypes.any }),
  isStickyActive: PropTypes.bool,
  addFocus: PropTypes.bool,
  pageId: PropTypes.string,
  isTop: PropTypes.bool,
  user: PropTypes.object,
  selectedGender: PropTypes.string,
  isMenDarkMode: PropTypes.bool,
};

export default CleanHeader;
