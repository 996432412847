import React, { Component } from 'react';
import Button from 'shared/components/Button/button';
import CloseIcon from 'shared/components/CloseIcon/closeIcon';
import { addBodyClass, removeBodyClass } from 'client-utils/bodyClass';
import { handleTabEventWith } from 'client-utils/handleTabbing';
import findTabbable from 'client-utils/tabbable';
import classnames from 'classnames';
import arrowDown from 'assets/images/arrow_down.svg';
import './mobileOption.scss';
import './inline-mobileOption.scss';

class MobileOption extends Component {
  constructor() {
    super();
    this.state = {
      opened: false,
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.handleTabEvent = this.handleTabEvent.bind(this);
    this.mobileOptionButton = null;
    this.mobileFilter = null;
    this.mobileOptionButtonRef = (mobileOptionButtonElement) => {
      this.mobileOptionButton = mobileOptionButtonElement;
    };
    this.mobileFilterRef = (mobileFilterElement) => {
      this.mobileFilter = mobileFilterElement;
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.opened) {
      this.state.opened && this.handleTabEvent();
    }
  }

  toggleBodyClass(opened = false) {
    const paneOpenClass = 'pane-open';
    const sortPaneOpenClass = 'sort-pane-open';

    if (opened) {
      addBodyClass(paneOpenClass);
      addBodyClass(sortPaneOpenClass);
    } else {
      removeBodyClass(paneOpenClass);
      removeBodyClass(sortPaneOpenClass);
    }
  }

  open() {
    this.setState({ opened: true });
    this.toggleBodyClass(true);
  }

  close() {
    this.setState({ opened: false });
    this.toggleBodyClass(false);
    this.mobileOptionButton && this.mobileOptionButton.focus();
  }

  handleTabEvent() {
    const mobileFilterWindow = this.mobileFilter;
    const focusableElements = findTabbable(mobileFilterWindow);
    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];
    setTimeout(() => {
      firstElement && firstElement.focus();
    }, 0);
    handleTabEventWith(firstElement, lastElement);
  }

  render() {
    const {
      title,
      dataTestid,
      component: ChildComponent,
      isABTestSRPVisualNavigation,
      options,
      isFacetButton,
      mobileFilterOptimizationAbTest,
    } = this.props;

    const className = 'faceted-mobile-option';
    const renderSortPanel = () => {
      return [
        <div
          key="mobile-option-pane"
          aria-label={title}
          className={`${className}__pane`}
          ref={this.mobileFilterRef}
          role="dialog"
        >
          <div className={`${className}__pane__header`}>
            <h2>SORT BY:</h2>
            <CloseIcon onClick={this.close} />
          </div>
          <ChildComponent
            {...this.props}
            close={this.close}
            selectedStore={this.props.selectedStore}
            clearStoreSelection={this.props.clearStoreSelection}
            omniFacetName={this.props.omniFacetName}
          />
        </div>,
        <div
          key="mobile-option-backdrop"
          className={`${className}__backdrop`}
          onClick={this.close}
        />,
      ]
    }

    if(isFacetButton){
      if(mobileFilterOptimizationAbTest) {
        return (
          <>
            <div 
              className="facet-button"
              onClick={() => {
                if(this.props.onSortFacetClick){
                  this.props.onSortFacetClick();
                }
                this.open();
              }}
            >
              <Button
                value={title}
                expanded={true}
                dataTestid={dataTestid}
                className={classnames('faceted-mobile-option__button button mobile-facet-button', 
                  { 'mobile-option__disabled': options?.length === 0 },
                )}
                forwardedRef={this.mobileOptionButtonRef}
                isABTestSRPVisualNavigation={isABTestSRPVisualNavigation}
                disabled={options?.length === 0}
              />
              <img
                alt="sort down icon"
                src={arrowDown}
                className="mobile-sort-down-icon"
              />
            </div>
            {this.state.opened
              && renderSortPanel()
            }
          </>
        )
      } else {
        return (
          <div className="facet-button">
            <Button
              value={title}
              expanded={true}
              dataTestid={dataTestid}
              className={classnames('faceted-mobile-option__button button mobile-facet-button', 
                { 'mobile-option__disabled': options?.length === 0 },
              )}
              onClick={() => {
                if(this.props.onSortFacetClick){
                  this.props.onSortFacetClick();
                }
                this.open();
              }}
              forwardedRef={this.mobileOptionButtonRef}
              isABTestSRPVisualNavigation={isABTestSRPVisualNavigation}
              disabled={options?.length === 0}
            />
            {this.state.opened
              && renderSortPanel()
            }
          </div>
        )
      }
    }

    return (
      <div className={`${className}`}>
        <Button
          dataTestid={dataTestid}
          className={classnames('faceted-mobile-option__button button visual-nav-button', { 'mobile-option__disabled': options?.length === 0 })}
          value={title}
          onClick={this.open}
          forwardedRef={this.mobileOptionButtonRef}
          isABTestSRPVisualNavigation={isABTestSRPVisualNavigation}
          disabled={options?.length === 0}
        />
        {this.state.opened
          && renderSortPanel()
        }
      </div>
    );
  }
}

export default MobileOption;
