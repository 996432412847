import React from 'react';
import classNames from 'classnames';
import './aToZGrid.scss';
import CustomLink from 'client/common/components/CustomLink/customLink';

const createGridItem = (val) => {
  const styles = classNames({
    'view-all': true,
    inactive: !val.url,
  });

  const gridElement = val.url
    ? (<CustomLink className={styles} url={val.url}>{val.name}</CustomLink>)
    : <span className={styles}>{val.name}</span>;

  return (
    <span className="a-to-z-data" key={val.name}>
      { gridElement }
    </span>
  );
};

const AtoZGrid = ({ dataToDisplay, titleToDisplay, globalNavUpdateToggle }) => {
  const columns = 5;
  const rows = Math.ceil(dataToDisplay.length / columns);
  const rowArr = [];
  let startIndex = 0;
  let endIndex = columns;
  for (let atozRow = 0; atozRow < rows; atozRow += 1) {
    const rowData = dataToDisplay.slice(startIndex, endIndex);
    startIndex = endIndex;
    endIndex += columns;
    rowArr.push(
      <div key={atozRow}>
        { rowData.map(createGridItem) }
      </div>,
    );
  }

  return (
    <div>
      <h6 className={classNames('designersByAlphaText', { 'globalNavToggleOn': globalNavUpdateToggle })}> {titleToDisplay}</h6>
      <div className="atoZGrid">
        {
          rowArr
        }
      </div>
    </div>
  );
};

export default AtoZGrid;
