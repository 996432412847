import React from 'react';
import './thumbnail.scss';
import CustomLink from 'client/common/components/CustomLink/customLink';


const thumbnail = ({
  id, name, imageUrl, url,
}) => {
  return (
    <div className="grid-33 tablet-grid-33 mobile-grid-50">
      <div className="product-thumbnail">
        <CustomLink className="SC-thumbnail__link" url={url}>
          <div className="product-thumbnail__image">
            <img className="SC_thumbnail_img" id={id} src={imageUrl} alt={name} />
          </div>
          <div>
            <h2 className="SC-thumbnail__display__title" dangerouslySetInnerHTML={{__html: name}}></h2>
          </div>
        </CustomLink>
      </div>
    </div>
  );
};

export default thumbnail;
