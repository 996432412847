import { ENTER_KEYCODE, SPACE_KEYCODE } from 'client-utils/keyCodes';
import { isDesktop, isMobile} from './utilities-page';

/**
 * Scrolls the page to the top of the product list wrapper.
 * @param {MouseEvent | KeyboardEvent} e
 */
export function scrollToTop(e) {
  e.preventDefault();

  /** @type {HTMLElement | null} */
  const productListWrapper = document.querySelector('.product-list-wrapper');

  const productListMobile = document.querySelector('.scrollmenu');

  if (isMobile() && productListMobile) {
    const rect = productListMobile.getBoundingClientRect().y - 500;
    window.scrollTo({
        top: rect,
        behavior: 'smooth'
    });
  } else if (productListWrapper && isDesktop()) {
    window.scrollTo({
      top: productListWrapper.getBoundingClientRect().y,
      behavior: 'smooth'
  });
  setTimeout( () => {
    if(document.querySelector('.header-wrapper--shadowed')) 
    document.querySelector('.header-wrapper--shadowed').classList.remove('header-wrapper--shadowed')
    document.querySelector('.shifted').classList.remove('shifted')
  }, 2000);
  } else {
    window.scrollTo(0, 0);
  }

  window.dispatchEvent(new window.CustomEvent('scrollToTop'));
  const skipToMainContentElement = document.getElementById('skipToMainContent');
  if (e.which === ENTER_KEYCODE || e.which === SPACE_KEYCODE) {
    if (skipToMainContentElement) {
      skipToMainContentElement.focus();
    }
    // Also scroll to the product list wrapper when Enter or Space is pressed
    if (isMobile() && productListMobile) {
      productListMobile.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    } else if (productListWrapper && isDesktop()) {
      productListWrapper.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }
  else if (e.type === 'click') {
    const contentContainerElement = document.getElementById('contentContainer');
    if (contentContainerElement) {
      contentContainerElement.focus();
    }
    // Scroll to the product list wrapper on click
    if (isMobile() && productListMobile) {
      productListMobile.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    } else if (productListWrapper && isDesktop()) {
      productListWrapper.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }
}
