import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import isUndefined from 'lodash/isUndefined';
import { shouldLoad } from 'universal/http-client';
import { openModal } from 'client/common/components/Modal/actions';
import {
  getModalContent, setActiveEntryId,
} from '../../actions/index';
// eslint-disable-next-line import/no-cycle
import { checkForICIDAndAddTags, renderMultiDeviceText } from '../utils';
import './simpleText.scss';
import CustomLink from 'client/common/components/CustomLink/customLink';

export function DumbSimpleText(props) {
  const {
    cmsContentItem = {},
    isMobilePhone,
    trackTags: propsTrackTags = {},
    openModal,
    getModalContent,
    setActiveEntryId,
  } = props;
  const {
    desktopText,
    tabletText,
    mobileText,
    link,
    textColor,
    openLinkInNewTab,
    trackingTags: ownTrackTags = [],
    fullWidthModal,
  } = get(cmsContentItem, 'fields', {});

  const onClickHandler = (e) => {
    e.preventDefault();

    const { modalContentApi } = props;
    const entryId = link;

    if (entryId && shouldLoad(modalContentApi)) {
      getModalContent(entryId);
    }

    setActiveEntryId(entryId);

    openModal({
      type: 'InfoModal',
      fullWidth: fullWidthModal,
    });
  };

  const Wrapper = ({ children }) => (
    <div className="simple-text" style={{ color: textColor }}>
      {link ? (
        <CustomLink
          url={checkForICIDAndAddTags(link, propsTrackTags, ownTrackTags,
            !openLinkInNewTab && !isUndefined(openLinkInNewTab))}
          openOnNewTab={openLinkInNewTab}
          className="text-link"
          onClick={(!openLinkInNewTab && !isUndefined(openLinkInNewTab))
            ? onClickHandler : undefined}
        >
          {children}
        </CustomLink>
      ) : (
        <>{children}</>
      )}
    </div>
  );

  return renderMultiDeviceText(
    Wrapper,
    {
      isMobilePhone,
      desktopText,
      tabletText,
      mobileText,
    }
  );
}

const mapDispatchToProps = ({
  openModal,
  getModalContent,
  setActiveEntryId,
});

const mapStateToProps = (state, ownProps) => {
  const contentId = ownProps.cmsContentItem?.sys?.id;

  return {
    isMobilePhone: get(state, 'device.isMobilePhone'),
    modalContentApi: state.api[`modal_content_${contentId}`.toLowerCase()],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DumbSimpleText);
