import React, { Component } from 'react';
import classnames from 'classnames';
import WithTappable from 'shared/components/Tappable/Tappable';
import find from 'lodash/find';
import './beautyReplenishment.scss';
import CustomLink from 'client/common/components/CustomLink/customLink';

class BeautyReplenishment extends Component {
  constructor(props) {
    super(props);

    this.replenishIntervals = [
      { text: 'One time only' },
      { text: 'Every 30 Days', value: 30 },
      { text: 'Every 45 Days', value: 45 },
      { text: 'Every 60 Days', value: 60 },
      { text: 'Every 90 Days', value: 90 },
      { text: 'Every 120 Days', value: 120 },
    ];

    this.state = {};

    this.setActiveReplenishment = this.setActiveReplenishment.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.getIntervalText = this.getIntervalText.bind(this);
    this.handleModalAnchorClick = this.handleModalAnchorClick.bind(this);
  }

  onMouseOver(interval) {
    this.setState({ hoveredInterval: interval });
  }

  onMouseOut() {
    this.setState({ hoveredInterval: null });
  }

  setActiveReplenishment(interval) {
    const {
      onIntervalSelect,
    } = this.props;
    onIntervalSelect(interval.value);
  }

  getIntervalText() {
    const {
      replenishInterval,
    } = this.props;
    const {
      hoveredInterval,
    } = this.state;

    const selectedInterval = find(
      this.replenishIntervals,
      (interval) => interval.value === replenishInterval
    );

    const text = hoveredInterval
      ? hoveredInterval.text
      : selectedInterval.text;
    return text && `: ${text}`;
  }

  handleModalAnchorClick(e) {
    const {
      openReplenishmentModal,
    } = this.props;

    e.preventDefault();
    openReplenishmentModal();
  }

  render() {
    const {
      openReplenishmentModal,
      replenishInterval,
    } = this.props;

    const getClassNames = (value) => classnames(
      'beauty-replenishment__button',
      'grid-100',
      'tablet-grid-100',
      'mobile-grid-100',
      { 'beauty-replenishment__button--active': value === replenishInterval }
    );

    return (
      <div className="beauty-replenishment grid-100 tablet-grid-100 mobile-grid-100 grid-parent">
        <div className="grid-100 tablet-grid-100 mobile-grid-100">
          <span className="beauty-replenishment__heading">
            <strong>
              Select Frequency
            </strong>
            { this.getIntervalText() }
          </span>
        </div>

        <div className="grid-100 tablet-grid-100 mobile-grid-100 beauty-replenishment__intervals">
          <ul className="beauty-replenishment__list">
            {
              this.replenishIntervals.map((interval, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li className="beauty-replenishment__list__item" key={index}>
                  <div className="beauty-replenishment__box grid-15 tablet-grid-30 mobile-grid-30">
                    <WithTappable
                      mouseOver={() => this.onMouseOver(interval)}
                      mouseOut={this.onMouseOut}
                    >
                      {(tapProps) => (
                        <button
                          type="button"
                          className={getClassNames(interval.value)}
                          onClick={() => this.setActiveReplenishment(interval)}
                          {...tapProps}
                        >
                          {interval.text}
                        </button>
                      )}
                    </WithTappable>
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
        {openReplenishmentModal && (
        <div className="beauty-replenishment__faq grid-100 tablet-grid-100 mobile-grid-100">
          <CustomLink
            url="#replenishment-modal"
            className="beauty-replenishment__faq--link"
            onClick={this.handleModalAnchorClick}
          >
            {'Replenish?'}
          </CustomLink>
        </div>
        )}
      </div>
    );
  }
}

export default BeautyReplenishment;
