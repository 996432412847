import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import pullAt from 'lodash/pullAt';
import values from 'lodash/values';
import { RecentSearchSuggestions } from 'srp/components/atoms/RecentSearchSuggestions/RecentSearchSuggestions';
import { SearchSuggestions } from 'srp/components/atoms/SearchSuggestions/SearchSuggestions';
import { openSearchBoxModal } from 'client/common/actions/actions-page';
import { updateTopProducts, setGenderTA } from 'client/srp/actions/actions';
import {
  isTypeAheadOn, formatTopItems, isModalOpen, isGenderOnHPandSRP,
} from 'srp/utils/srpUtils';
import { getSelectedGender, getGenderWithOverride } from 'client-utils/utilities-gender';
import { setGender, setTypeaheadUtag } from 'shared/components/App/app-actions';
import { GENDER_PLACEMENT } from 'universal/constants/genderPlacement';
import { getStorageKey, INVALID_REGEX_PATTERN } from 'storefront/components/Header/utils/HeaderUtils';
import { WOMEN } from 'shared/constants';
import { updateSearchTermResult } from 'clientHorchow/components/SearchBox/search-actions';	
import Link from 'client/components/lib/Link/index';
import { loadTypeaheadUIConfig, setMobileTextBoxValue, setRecentlySearchedAnalytics, genderToggleAnalyticsReset } from '../search-actions';
import {
  getIsTypeaheadSuggestionEmpty,
  getMobileSearchTerm, getSearchVisible, getTypeaheadUIConf, typeaheadSuggestionPayload,
} from '../search-reducers';
import GenderSelectTA from '../SearchBox/GenderSelectTA/genderSelectTA';
import { searchFormSubmit, genderSelectHandler } from '../submitHelper';
import { saveToLocalStorage } from 'client-utils/utilities-storage';

import './searchBoxMobile.scss';
import '../bloomreach.scss';
import CustomLink from 'client/common/components/CustomLink/customLink';

export class DumbSearchBoxMobile extends Component {
  constructor(props) {
    super(props);
    const {
      dtGender,
      isGenderTypeahead,
      typeaheadUIConf,
      typeAheadExperience,
    } = props;
    const isGenderTypeaheadAvailable = isGenderTypeahead
      && typeAheadExperience
      && !isEmpty(typeaheadUIConf.women) && !isEmpty(typeaheadUIConf.men);
    const genderUIConfig = dtGender === WOMEN ? typeaheadUIConf.women : typeaheadUIConf.men;
    const selectedUIConfig = isGenderTypeaheadAvailable ? genderUIConfig : typeaheadUIConf;


    this.state = {
      inputIsFocused: false,
      recentSearchHover: false,
      isBarClicked: false,
      selectedGender: dtGender,
      typeaheadUIConf: selectedUIConfig,
      topProducts: {},
      isGenderTypeaheadAvailable,
      typeaheadSuggestions: {},
      isSuggestionSelected: false,	
      suggestions: [],
    };

    this.handleSelectionOnRecentSearch = this.handleSelectionOnRecentSearch.bind(this);
    this.getTypeaheadSuggestions = this.getTypeaheadSuggestions.bind(this);
    this.onSearchTermChange = this.onSearchTermChange.bind(this);

    this.recentSearchRef = createRef();
    this.mobileBrSearchInput = null;
    this.modalBrSearchInput = null;
    this.searchIcon = null;
    this.setMobileBrSearchInput = (element, insideModal) => {
      if (insideModal) {
        this.modalBrSearchInput = element;
      } else {
        this.mobileBrSearchInput = element;
      }
    };
    this.setSearchIcon = (element) => {
      this.searchIcon = element;
    };
    this.toggleGenderTA = this.toggleGenderTA.bind(this);
    this.toggleSessionGenderTA = this.toggleSessionGenderTA.bind(this);


    this.handleReset = (isSearchModalOpen) => {
      if (this.mobileBrSearchInput) this.mobileBrSearchInput.focus();
      if (isSearchModalOpen) {
        setTimeout(() => this.modalBrSearchInput.focus(), 20);
      }
    };

    this.handleOnBlur = () => {
      this.setState({
        inputIsFocused: false,
        selectedGender: this.props.dtGender,
      });
      if (this.searchIcon) this.searchIcon.disabled = false;
    };

    this.setFocusAtEnd = (searchIcon) => {
      searchIcon.target.disabled = true;
      if (this.mobileBrSearchInput) {
        const searchTerm = this.mobileBrSearchInput.value;
        this.mobileBrSearchInput.value = '';
        this.mobileBrSearchInput.value = searchTerm;
        this.mobileBrSearchInput.focus();
      }
    };
    this.onRecentSearchHover = this.onRecentSearchHover.bind(this);
    this.openRecentSearchOnTouch = this.openRecentSearchOnTouch.bind(this);
    this.changeGender = this.changeGender.bind(this);
  }

  componentDidMount() {
    const {
      searchTerm,
      setRecentlySearchedAnalytics,
      isMobilePhone,
      routing,
      pageId,
      isSearchModalOpen,
      insideModal,
      typeAheadExperience,
      isGenderNavOn,
      isGenderTypeahead,
      taGender,
      topProducts,
      typeaheadSuggestionPayload,
      brand,
    } = this.props;
    const { isGenderTypeaheadAvailable, selectedGender } = this.state;
    const storageKey = getStorageKey({ isGenderTypeahead, typeAheadExperience, taGender });

    const recentSearchSample = typeAheadExperience ? 7 : 5;
    if (isMobilePhone) {
      try {
        const recentSearches = typeof (localStorage) !== 'undefined'
          ? (JSON.parse(localStorage.getItem(storageKey)) || [])
          : false;
        const query = routing.query && routing.query.filterOptions
          ? JSON.parse(routing.query.filterOptions)
          : null;
        const queryExists = query ? values(query).some((val) => { return val.length > 0; }) : false;
        if (recentSearches && recentSearches.length >= recentSearchSample) {
          recentSearches.splice(recentSearchSample, recentSearches.length);
        }
        if (searchTerm !== '') {
          if (recentSearches) {
            const repeatedSearchValue = recentSearches.indexOf(searchTerm);
            if (repeatedSearchValue > -1 && pageId.indexOf('SRP') !== -1) {
              if (
                window.utag_data_dt
                && !(window.utag_data_dt.recently_searched !== undefined)
                && !queryExists
              ) {
                if (window.sessionStorage.getItem('recently_searched') === 'true') {
                  setRecentlySearchedAnalytics(true);
                  window.sessionStorage.removeItem('recently_searched');
                }
              } else {
                setRecentlySearchedAnalytics(false);
              }
              pullAt(recentSearches, repeatedSearchValue);
            } else {
              setRecentlySearchedAnalytics(false);
            }
            recentSearches.unshift(searchTerm);
          }
        }
        typeof (localStorage) !== 'undefined'
          && saveToLocalStorage(storageKey, JSON.stringify(recentSearches));
      } catch (e) {
        localStorage.removeItem(storageKey);
      }
    }
    if (isSearchModalOpen) {
      setTimeout(() => this.modalBrSearchInput.focus(), 20);
    }
    if (isGenderNavOn) {
      window.sessionStorage.setItem('ta_gender', getSelectedGender());
    }
    if (!isGenderNavOn && brand === 'NM') {
      window.sessionStorage.setItem('ta_gender', getSelectedGender());
    }

    // Populates initial BR results if there's a previous term
    if (typeAheadExperience && insideModal && searchTerm.length > 0) {
      if (typeof window !== 'undefined' && window.$ && window.BRAutosuggest) {
        window.BRAutosuggest.search(searchTerm);
      }
    }

    this.setState({
      topProducts: isGenderTypeaheadAvailable ? this.getGenderTopProducts() : topProducts,
      typeaheadSuggestions: searchTerm && isGenderTypeahead
        ? this.getTypeaheadSuggestions(selectedGender)
        : typeaheadSuggestionPayload,
    });
  }

  componentDidUpdate(prevProps) {
    const { selectedGender } = this.state;
    if (this.props.typeaheadSuggestionPayload !== prevProps.typeaheadSuggestionPayload) {
      this.onSearchTermChange(selectedGender);
    }
    if (prevProps.typeaheadPayload !== this.props.typeaheadPayload) {	
      // eslint-disable-next-line react/no-did-update-set-state	
      this.setState({	
        suggestions: this.props.typeaheadPayload,	
        isSuggestionSelected: true,	
      });	
    }
  }
  
  onRecentSearchHover(hoverStatus) {
    this.setState((prevState) => {
      const resetGenderValue = hoverStatus === false
        ? this.props.dtGender
        : this.state.selectedGender;
      return {
        ...prevState,
        recentSearchHover: hoverStatus,
        selectedGender: resetGenderValue,
      };
    });
  }

  onSearchTermChange(selectedGender) {
    const { isGenderTypeahead, typeaheadSuggestionPayload } = this.props;
    this.setState({
      typeaheadSuggestions: isGenderTypeahead
        ? this.getTypeaheadSuggestions(selectedGender)
        : { ...typeaheadSuggestionPayload },
    });
  }

  getGenderTopProducts() {
    const {
      topProducts,
      typeaheadUIConf: {
        women: { topProducts: womenTopProductsIds },
        men: { topProducts: menTopProductsIds },
      },
    } = this.props;
    const womenTopProducts = [];
    const menTopProducts = [];

    topProducts.forEach((product) => {
      if (womenTopProductsIds?.includes(product.id)) womenTopProducts.push(product);
      if (menTopProductsIds?.includes(product.id)) menTopProducts.push(product);
    });

    return { women: womenTopProducts, men: menTopProducts };
  }

  getTypeaheadSuggestions(gender) {
    const { typeaheadSuggestionPayload } = this.props;
    const { women: womenSuggestions, men: menSuggestions } = typeaheadSuggestionPayload;
    return Object.assign(typeaheadSuggestionPayload, gender !== 'M' ? womenSuggestions : menSuggestions);
  }

  toggleGenderTA(gender) {
    this.setState(() => {
      return {
        selectedGender: gender,
      };
    });
  }

  toggleSessionGenderTA(gender) {
    window.sessionStorage.setItem('ta_gender', gender);
    this.props.setGenderTA(gender, true);
  }

  handleSelectionOnRecentSearch(value) {
    const { handleOnChange, setRecentlySearchedAnalytics } = this.props;
    const thisContext = this;
    if (value) {
      setRecentlySearchedAnalytics(true);
      handleOnChange(value);
      setTimeout(() => {
        if (thisContext.mobileSearchRef) {
          thisContext.mobileSearchRef.click();
        }
      }, 200);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        recentSearchHover: false,
        inputIsFocused: false,
      }));
    }
  }

  handleTextChange(value) {	
    const {	
      handleOnChange, brand, getSearchResults, searchURL,	
    } = this.props;	
    if (brand === 'HC' && value !== '') {	
      getSearchResults(value, searchURL);	
    }	
    handleOnChange(value);	
  }

  openRecentSearchOnTouch() {
    this.setState((prevState) => ({
      ...prevState,
      inputIsFocused: true,
    }));
    this.mobileBrSearchInput && this.mobileBrSearchInput.focus();
  }

  UNSAFE_componentWillMount() {
    const {
      loadTypeaheadUIConfig,
      typeAheadExperience,
      isGenderTypeahead,
      dtGender,
      topProducts,
      updateTopProducts,
    } = this.props;
    if (!IS_CLIENT && global.typeaheadDataObj) {
      const { topProducts: commonTopProducts = [], women, men } = global.typeaheadDataObj;

      loadTypeaheadUIConfig();
      if (typeAheadExperience) {
        if (isEmpty(topProducts)) {
          const topProductsConfig = women?.topProducts && men?.topProducts && isGenderTypeahead
            && typeAheadExperience && dtGender
            ? [...women.topProducts, ...men.topProducts]
            : commonTopProducts;

          if (!isEmpty(topProductsConfig)) {
            const docsArray = topProductsConfig.map((id) => {
              return { pId: id };
            });
            const body = {
              docs: docsArray,
              toggles: {
                USE_CLOUDINARY_IMAGES: true,
              },
            };
            const cacheKey = topProductsConfig.map((id) => id).toString();
            updateTopProducts(body, `${cacheKey}`);
          }
        }
      }
    }
  }

  changeGender(gender) {
    const genderKey = gender === WOMEN ? 'women' : 'men';
    const { typeaheadUIConf, searchTerm, isGenderTypeahead } = this.props;

    this.setState({
      selectedGender: gender,
      typeaheadUIConf: typeaheadUIConf[genderKey] || typeaheadUIConf,
      typeaheadSuggestions: (searchTerm && isGenderTypeahead)
        ? this.getTypeaheadSuggestions(gender)
        : typeaheadSuggestionPayload,
    });

    window.sessionStorage.setItem('ta_gender', gender);
    this.props.setGenderTA(gender, true);
  }

  searchSuggestion(suggestion, searchTerm) {
    // Use the replace method to remove invalid characters
    if(searchTerm){
      searchTerm = searchTerm.replace(INVALID_REGEX_PATTERN, '');
    }
    const parts = suggestion.split(new RegExp(`(${searchTerm})`, 'gi'));
    return <span>{parts.map((part) => (part.toLowerCase() === searchTerm.toLowerCase() ? <span className="match">{part}</span> : part))}</span>;
  }

  render() {
    const {
      handleOnChange,
      searchTerm,
      removeSnapFindShop,
      topProducts,
      isTypeaheadSuggestionEmpty,
      openSearchBoxModal,
      insideModal,
      isNewSrpUI,
      setTypeaheadUtag,
      isGenderNavOn,
      isSearchModalOpen,
      isGenderTypeahead,
      typeAheadExperience,
      taGender,
      dtGender,
      setGender,
      isDomestic,
      brand,
    } = this.props;
    const {
      inputIsFocused,
      recentSearchHover,
      isBarClicked,
      selectedGender,
      topProducts: { women, men },
      isGenderTypeaheadAvailable,
      typeaheadUIConf,
      typeaheadSuggestions,
      suggestions,	
      isSuggestionSelected,
    } = this.state;
    const isGenderTypeaheadOn = typeAheadExperience && isGenderTypeahead;
    const storageKey = getStorageKey({ isGenderTypeahead, typeAheadExperience, taGender });
    const genderTopProducts = selectedGender === WOMEN ? women : men;
    const selectedTopProducts = isGenderTypeaheadAvailable ? genderTopProducts : topProducts;
    const topItems = (isGenderTypeaheadOn && !isEmpty(selectedTopProducts)) ? formatTopItems(selectedTopProducts) : [];

    if (brand !=='HC' && typeAheadExperience && isBarClicked && !insideModal) {
      const modalClassName = 'search-box-modal';
      const modalProps = { testProp: true };
      openSearchBoxModal(modalClassName, modalProps);
      this.setState({ isBarClicked: false });
      document.getElementById('mobileBrSearchInput').blur();
    }

    const getResetOrSnapButton = (showSnapButton) => {
      const exposedSearchBoxResetButtonClassName = classnames('exposed-search-reset-btn--shown',
        { 'hide-reset-text': !insideModal },
        { 'exposed-search-box-mobile__reset': !insideModal },
        { 'exposed-search-box-mobile__reset-inside': insideModal },
        { 'typeahead--reset': typeAheadExperience && !insideModal },
        { 'typeahead--reset-inside': typeAheadExperience && insideModal });
      const exposedSearchBoxSnapButtonClassName = 'exposed-search-box-mobile__reset exposed-search-reset-btn--hidden';
      if (!showSnapButton) {
        if (typeAheadExperience) {
          return (
            <button
              type="reset"
              name="reset-search"
              aria-label="clear"
              title="reset-search"
              value="reset-search"
              className={exposedSearchBoxResetButtonClassName}
              onClick={() => { handleOnChange(''); this.handleReset(isSearchModalOpen); }}
            >
              Clear
            </button>
          );
        }
        return (
          <input
            type="reset"
            name="reset-search"
            title="reset-search"
            value="reset-search"
            className={exposedSearchBoxResetButtonClassName}
            onClick={() => { handleOnChange(''); this.handleReset(isSearchModalOpen); }}
          />
        );
      }
      return (!removeSnapFindShop
        && (
        <CustomLink url={NMConfig.ATG_SNAP_FIND_SHOP_URL} title="SNAP. FIND. SHOP." id="snapFindShopId">
          <input type="button" name="snap-search" title="snap-search" className={exposedSearchBoxSnapButtonClassName} aria-label="Snap Find Shop" />
        </CustomLink>
        ));
    };

    const exposedSearchBoxClassNames = 'mobile-only exposed-search-box-mobile--visible-sticky';
    const paddingForTypeAhead = () => {
      if (typeAheadExperience) {
        if (insideModal) {
          return { paddingLeft: '0px' };
        }
        return { padding: '10px 10px 0' };
      }
      return { padding: '10px 10px 0' };
    };
    const transformForTypeAhead = insideModal ? { transform: 'TranslateX(-10px)' } : {};
    const insideModelID = !insideModal ? 'mobileBrSearchInput' : 'modalBrSearchInput';	
    const inputIDElement = brand === 'HC' ? 'elSearchInput' : insideModelID;

    const showSnapButton = !searchTerm;
    const suggestionsList = brand === 'HC' && suggestions.length > 0 && searchTerm !== '' ? (	
      suggestions.slice(0, 10).map((suggestion, index) => {	
        return (	
          <li key={index}>	
            <Link	
              aria-label={`Shop more: ${suggestion}`}	
              target="_self"	
              to={{	
                pathname: `${isNewSrpUI ? '/s/' : '/search.jsp'}`,	
                query: typeAheadExperience? {	
                  from: 'brSearch',	
                  responsive: 'true',	
                  request_type: 'search',	
                  search_type: 'keyword',	
                  q: `${suggestion}`,	
                  l: `${suggestion}`,	
                }
                 : {	
                    from: 'brSearch',	
                    responsive: 'true',	
                    request_type: 'search',	
                    search_type: 'keyword',	
                    q: `${suggestion}`,	
                    l: `${suggestion}`,	
                    src: 'suggest',	
                    dq: `${suggestion}`,	
                    aq: `${searchTerm}`,	
                    fl: '',	
                  },	
              }}	
            >	
              {
                this.searchSuggestion(suggestion, searchTerm)
              }	
            </Link>	
          </li>	
        );	
      })	
    ) : (<li />);
    return (
      <div className={`mobile-only exposed-search-box-search-container ${isBarClicked ? 'search-open' : ''}`}>
        <form
          action={isNewSrpUI ? '/s/' : '/search.jsp'}
          className={classnames(exposedSearchBoxClassNames, { 'exposed-search-box-mobile--shifted': isGenderTypeaheadOn })}
          onSubmit={(e) => {
            searchFormSubmit(e, searchTerm);
            genderSelectHandler(isGenderTypeaheadOn, dtGender, selectedGender, setGender);
          }
          }
          method="GET"
          style={paddingForTypeAhead()}
        >
          <input title="from-input" name="from" type="hidden" value={brand === 'HC' ? "elSearch" : "brSearch"} />
          <input title="l-input" name="l" type="hidden" value={searchTerm} />
          <input
            type="button"
            ref={(input) => input && this.setSearchIcon && this.setSearchIcon(input)}
            name="search-icon"
            title="search icon"
            aria-label="Get Results"
            className={
              classnames(
                'exposed-search-box-mobile__search-icon',
                { 'typeahead--search': typeAheadExperience },
                { 'exposed-search-box-mobile__search-icon--focused': inputIsFocused }
              )
            }
            onClick={(e) => { this.setFocusAtEnd(e); }}
            onFocus={() => {
              if (inputIsFocused) {
                setTimeout(() => { this.modalBrSearchInput.focus(); }, 20);
              }
            }}
            style={transformForTypeAhead}
          />
          <input
            ref={(input) => input && this.setMobileBrSearchInput(input, insideModal)}
            title="search-term-input"
            placeholder="Search"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            aria-label="Search Box"
            id={inputIDElement}
            name="q"
            className={classnames(
              { 'exposed-search-box-mobile__text': !typeAheadExperience },
              { 'exposed-search-box-mobile__text-typeahead': typeAheadExperience && !insideModal },
              { 'exposed-search-box-mobile__text-typeahead-inside': typeAheadExperience && insideModal }
            )}
            value={searchTerm}
            onChange={(e) => this.handleTextChange(e.target.value)}
            onBlur={() => !insideModal && this.handleOnBlur()}
            onFocus={() => { this.setState({ inputIsFocused: true, isBarClicked: true }); }}
            onTouchEnd={() => { this.openRecentSearchOnTouch(); }}
          />
          {
            isDomestic && (isGenderNavOn || isGenderTypeaheadOn) && brand !== 'HC' && inputIsFocused
              && (
                <GenderSelectTA
                  onGenderSelected={(gender) => {
                    genderToggleAnalyticsReset();
                    this.changeGender(gender);
                  }}
                  selectedGender={selectedGender}
                  isTypeAhead={typeAheadExperience}
                />
              )
          }
          <div className="brm-autosuggest-wrap-mobile"><div id="newBRTypeAhead" /></div>
          { getResetOrSnapButton(showSnapButton) }
          <input
            ref={(mobileSearchRef) => { this.mobileSearchRef = mobileSearchRef; }}
            id="mobile-hidden-submit"
            type="submit"
            name="search-submit"
            title="search-submit"
            value="search"
            style={{ display: 'none' }}
          />
          { isEmpty(searchTerm)
            && !typeAheadExperience
            && (inputIsFocused || recentSearchHover)
            && (
              <div className="recent-search-renderer">
                <RecentSearchSuggestions
                  closeRecentSearchOnClear={this.onRecentSearchHover}
                  mobileView
                  forwardRef={this.recentSearchRef}
                  handleSelection={this.handleSelectionOnRecentSearch}
                />
              </div>
            )
          }
          { typeAheadExperience
            && (inputIsFocused || recentSearchHover)
            && (
              <div className={`search-suggestions-renderer  ${isBarClicked ? 'search-open' : ''}`}>
                <SearchSuggestions
                  closeRecentSearchOnClear={this.onRecentSearchHover}
                  handleSelection={this.handleSelectionOnRecentSearch}
                  mobileView
                  arrowIndex={-1}
                  forwardRef={this.recentSearchRef}
                  typeaheadData={typeaheadUIConf}
                  typeaheadSuggestionPayload={typeaheadSuggestions}
                  searchTerm={searchTerm}
                  topItems={topItems}
                  noResults={isTypeaheadSuggestionEmpty}
                  selectedGender={selectedGender}
                  toggleGenderTA={this.toggleGenderTA}
                  isGenderTypeahead={isGenderTypeahead}
                  setGender={this.props.setGender}
                  setTypeaheadUtag={setTypeaheadUtag}
                  storageKey={storageKey}
                  onListClick={() => genderSelectHandler(
                    isGenderTypeaheadAvailable, dtGender, selectedGender, setGender
                  )}
                  brand={brand}
                />
              </div>
            )
          }
          {isSuggestionSelected	
            && brand === 'HC'
            ? (	
              <div className="make-relative br-search-static">	
                {isSuggestionSelected ? (	
                  <div className="autosuggest-wrap">	
                    <ul className="ui-autocomplete">	
                      {suggestionsList}	
                    </ul>	
                  </div>	
                ) : ''}	
              </div>	
            )	
            : ''}
        </form>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const dtGender = getGenderWithOverride(state);

  return {
    searchTerm: getMobileSearchTerm(state),
    isVisible: getSearchVisible(state),
    countryCode: state.locale.countryCode,
    removeSnapFindShop: state.toggles.REMOVE_SNAP_FIND_SHOP,
    isMobilePhone: get(state, 'device.isMobilePhone', false),
    pageId: get(state, 'page.pageId', ''),
    routing: get(state, 'routing.locationBeforeTransitions', {}),
    typeAheadExperience: isTypeAheadOn(state),
    typeaheadUIConf: getTypeaheadUIConf(state),
    isSearchModalOpen: isModalOpen(state, 'SearchBoxModal'),
    typeaheadSuggestionPayload: typeaheadSuggestionPayload(state),
    isTypeaheadSuggestionEmpty: getIsTypeaheadSuggestionEmpty(state),
    topProducts: get(state, 'srp.search.topProducts', []),
    isNewSrpUI: get(state, 'toggles.SRP_NEW_UI', false),
    selectedGender: get(state, 'srp.search.ta_gender'),
    isGenderNavOn: isGenderOnHPandSRP(state),
    isGenderTypeahead: get(state, 'toggles.GENDER_TYPEAHEAD', false),
    dtGender,
    taGender: get(state, 'srp.search.ta_gender', dtGender),
    isDomestic: get(state, 'locale.countryCode') === 'US',
    brand: state.brand_name.env,	
    searchURL: get(state, 'hcSearch.searchURL', ''),	
    typeaheadPayload: get(state, 'hcSearch.typeaheadPayload', {}),
  };
};

const mapDispatchToProps = ({
  handleOnChange: setMobileTextBoxValue,
  getSearchResults: updateSearchTermResult,
  setRecentlySearchedAnalytics,
  openSearchBoxModal,
  loadTypeaheadUIConfig,
  updateTopProducts,
  setGender: (gender) => setGender(gender, GENDER_PLACEMENT.HEADER),
  setTypeaheadUtag,
  setGenderTA,
});

export default connect(mapStateToProps, mapDispatchToProps)(DumbSearchBoxMobile);
