import Button from "client/common/components/Button/button";
import {
  handleFilterSelection,
  openFacetedMobileFilter,
  toggleCategorySelector,
} from "client/plp/components/ProductListPage/components/FacetedLeftNav/actions";
import { addBodyClass } from "client-utils/bodyClass";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./mobileFacetButtons.scss";
import { get, isEmpty, isEqual } from "lodash";
import MobileOption from "../MobileOption/mobileOption";
import { MOBILE_FACET_BUTTONS_LIST, FILTER_LABEL, SORT_LABEL, MVP_FILTER } from "client/plp/constants";
import { removeMvpFilterUtag, updateMvpFilterUtag } from "client/plp/components/ProductListPage/actions";
import { ABTEST_MOBILE_FILTER_OPTIMIZATION } from 'client/common/actions/actions-page';
import filter from 'assets/images/filter.svg';

const SortFacetButton = ({
  sortOptions,
  selectedValue,
  onSortChange,
  component: MobileSort,
  onSortFacetClick,
  mobileFilterOptimizationAbTest,
}) => {
  return (
    <MobileOption
      secondary
      title={mobileFilterOptimizationAbTest ? "Sort By" : "SORT"}
      dataTestid="mobile-sort-button"
      options={sortOptions}
      selectedValue={selectedValue}
      onClick={onSortChange}
      component={MobileSort}
      headerTitle="SORT BY:"
      isFacetButton={true}
      onSortFacetClick={onSortFacetClick}
      mobileFilterOptimizationAbTest={mobileFilterOptimizationAbTest}
    />
  );
};

const MobileFacetFilterButtons = ({
  openFacetedMobileFilter,
  noOfFiltersSelected,
  facetedFiltersList,
  applicableFilters,
  sortOptions,
  selectedValue,
  onSortChange,
  component,
  toggleCategorySelector,
  handleFilterSelection,
  plpInteraction,
  updateMvpFilterUtag,
  removeMvpFilterUtag,
  mobileFilterOptimizationAbTest,
  totalItems,
}) => {
  const [filterFacetsCount, setFiltersCount] = useState([]);
  const [facetButtonsList, setFacetButtonsList] = useState(MOBILE_FACET_BUTTONS_LIST);
  const [selectedFacet, setSelectedFacet] = useState('');

  const getSelectedFiltersCount = (facetFilterObj, facetName) => {
    const count = Object.values(facetFilterObj).reduce((total, item) => total + (item === true ? 1 : 0), 0);
    return { facetName, selectedFiltersCount: count };
  };

  useEffect(() => {
    if(selectedFacet === SORT_LABEL && plpInteraction === MVP_FILTER){
      removeMvpFilterUtag();
      setSelectedFacet('');
    }
  }, [selectedValue])

  useEffect(() => {
    if (!isEmpty(facetedFiltersList)) {
      const filterButtons = MOBILE_FACET_BUTTONS_LIST.filter((facetName) => 
        !(facetName === FILTER_LABEL || facetName === SORT_LABEL));
      const filtersList = applicableFilters.filter((filter) => filterButtons.includes(filter.displayText));
      const selectedFacetsCount = filtersList.map(filter => {
        return getSelectedFiltersCount(facetedFiltersList[filter.filterKey], filter.displayText);
      });

      if(selectedFacet && !isEqual(filterFacetsCount, selectedFacetsCount)) {
        if(selectedFacet === FILTER_LABEL || selectedFacet === SORT_LABEL){
          removeMvpFilterUtag();
        } else {
          updateMvpFilterUtag(MVP_FILTER);
        }
        setSelectedFacet('');
      }

      setFiltersCount(selectedFacetsCount);
    }
  }, [facetedFiltersList]);

  useEffect(() => {
    if(sortOptions?.length === 0){
      const facetsList = facetButtonsList.filter((facet) => facet !== SORT_LABEL);
      setFacetButtonsList(facetsList);
    }
  }, [sortOptions])

  useEffect(() => {
    const updatedFacetsList = getUpdatedFacetsList(MOBILE_FACET_BUTTONS_LIST);
    if (!isEqual(updatedFacetsList, facetButtonsList)) {
      setFacetButtonsList(updatedFacetsList);
    }
  }, [applicableFilters, facetedFiltersList]);

  const onFacetButtonClick = (facetName) => {
    addBodyClass("pane-open");
    if (facetName !== FILTER_LABEL) {
      const filterObj = applicableFilters.find((filter) => filter.displayText === facetName);
      if (filterObj && filterObj.filterKey) {
        handleFilterSelection(filterObj.filterKey);
      }
    } else {
      toggleCategorySelector();
    }

    setSelectedFacet(facetName);
    openFacetedMobileFilter();
  };

  const onSortFacetClick = () => {
    setSelectedFacet(SORT_LABEL);
  }

  const getFacetButtonTitle = (facetName) => {
    if (facetName === FILTER_LABEL && noOfFiltersSelected > 0) {
      return `${facetName} (${noOfFiltersSelected})`;
    }

    const facetObj = filterFacetsCount.find((filter) => filter.facetName === facetName);
    if (facetObj && facetObj.selectedFiltersCount) {
      return `${facetName} (${facetObj.selectedFiltersCount})`;
    }

    return facetName;
  };

  const getUpdatedFacetsList = (facetItems) => {
    return facetItems.filter(facetName => {
      return (
        facetName === FILTER_LABEL || facetName === SORT_LABEL ||
        applicableFilters.find(filter => filter.displayText === facetName)
      );
    });
  };

  return (
    <>
      {mobileFilterOptimizationAbTest ? (
        <div className="mobile-filter-container">
          {facetButtonsList.map(facetName => {
            const title = getFacetButtonTitle(facetName);
            if (title.startsWith("Filter")) {
              return (
                <div
                  key={facetName}
                  className="mobile-filter-item filter-option"
                >
                  <div 
                    className="mobile-filter" 
                    onClick={() => onFacetButtonClick(facetName)}>
                      <img
                        alt="filter icon"
                        src={filter}
                        className="mobile-filter-icon"
                      />
                      <Button
                        className="button mobile-facet-button"
                        value={title}
                      />
                  </div>
                  <div className="total-item-count">{`${totalItems} Items`}</div>
                </div>
              );
            }
            if (title === SORT_LABEL) {
              if (sortOptions.length > 0) {
                return (
                  <div className="mobile-filter-item sort-option">
                    <SortFacetButton
                      sortOptions={sortOptions}
                      selectedValue={selectedValue}
                      onSortChange={onSortChange}
                      component={component}
                      onSortFacetClick={onSortFacetClick}
                      mobileFilterOptimizationAbTest={mobileFilterOptimizationAbTest}
                    />
                  </div>
                );
              }
              return;
            }
          })}
        </div>
      ) : (
        <div className="scroll-menu">
          {facetButtonsList.map(facetName => {
            const title = getFacetButtonTitle(facetName);

            if (title === SORT_LABEL) {
              if (sortOptions.length > 0) {
                return (
                  <SortFacetButton
                    sortOptions={sortOptions}
                    selectedValue={selectedValue}
                    onSortChange={onSortChange}
                    component={component}
                    onSortFacetClick={onSortFacetClick}
                  />
                );
              }
              return;
            }

            return (
              <div key={facetName} className="facet-button">
                <Button
                  className="button mobile-facet-button"
                  value={title}
                  onClick={() => onFacetButtonClick(facetName)}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  facetedFiltersList: get(state, "facetedLeftNav.facetedFiltersList", {}),
  applicableFilters: get(state.productListPage, "products.applicableFilters", []),
  plpInteraction: get(state.utag, "productListPage.plp_interaction", null),
  mobileFilterOptimizationAbTest: get(state, `abTestsOpt.${ABTEST_MOBILE_FILTER_OPTIMIZATION}.variation`, 'a') === 'b'
});

const mapDispatchToProps = {
  openFacetedMobileFilter,
  toggleCategorySelector,
  handleFilterSelection,
  updateMvpFilterUtag,
  removeMvpFilterUtag
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileFacetFilterButtons);
