import React from 'react';
import take from 'lodash/take';
import get from 'lodash/get';
import classnames from 'classnames';
import { unescape } from 'he';
import LazyLoad from 'react-lazy-load';
import SwatchImage from 'pdp/components/Image/image';
import { Link } from 'react-router';
import Toggle from 'shared/components/Toggle/toggle';
import {
  SWATCHES_LIMIT_MOBILE_MODERN,
  SWATCHES_LIMIT_TABLET_MODERN,
  SWATCHES_LIMIT_DESKTOP_MODERN,
  SHOW_MORE_COLORS_AVAILABLE_TEXT,
  SWATCH_IMAGE_LOAD_TIMEOUT,
} from 'plp/constants';
import modernPlusSign from 'assets/images/modernExpand.svg';
import './colorSwatches.scss';

function ColorSwatches({
  colorOptions = {},
  url,
  selectedColorSwatchIndex,
  changeSelectedColorSwatchIndex,
  onMouseOverHandler,
  onMouseOutHandler,
  errorMessage,
  productId,
  updateSwatchesValue,
  plpImageSwatchLazyLoadBeta = false,
  moveProductTogglesToList = false,
  isPlp,
  searchFlow,
  newProductThumbStyle,
  hideSwatchOnErrorToggle = false,
  swatchOptimizationToggle = false,
  hasMoreSwatches = false,
  useCloudinaryImages = true,
  brand,
}) {
  const options = { values: colorOptions };

  const values = take(options.values, SWATCHES_LIMIT_DESKTOP_MODERN + 1);
  const swatches = {
    values,
    missingColorSwatchesCount: options.missingColorSwatchesCount,
  };

  function isDisplayableSwatch(options) {
    const swatchLength = options.values.length;
    return swatchLength > 1;
  }

  const isPlpOrSrp = isPlp || searchFlow;
  const swatchList = classnames(
    'product-thumbnail__swatches__item',
    'product-thumbnail__swatches__item__modern',
    'product-thumbnail__swatches__item--more-swatches',
    'product-thumbnail__swatches__item__modern--more-swatches',
    {
      'hide-on-desktop': !hasMoreSwatches,
      'hide-on-mobile': !hasMoreSwatches,
      'hide-on-tablet': !hasMoreSwatches,
    },
  );

  function changeAlertText(currentTarget, swatchName) {
    currentTarget.parentNode.lastChild.innerText = `${swatchName} selected`;
  }

  function renderSwatch(swatch, swatchName,
    errorMessage,
    index,
    productId,
    updateSwatchesValue,
    isPlp,
    hideSwatchOnErrorToggle,
    swatchOptimizationToggle,
    useCloudinaryImages,
    brand,) {
    const hcErrorImage = '//media.horchow.com/image/upload/v1632927419/horchow-swatch-unavailable.jpeg';
    const className = 'swatch-border-box';
    const errorImageUrl = brand === 'HC' ? hcErrorImage : swatch.errorUrl;
    let defaultProps = {
      'data-color-name': swatchName,
      'data-color-key': swatch.key,
      dataColorName: swatchName,
      productId: productId,
      src: swatch.url,
      alt: swatchName,
      title: swatchName,
      errorImage: errorImageUrl,
      errorMessage,
      timeout: SWATCH_IMAGE_LOAD_TIMEOUT,
      className,
      useCloudinaryImages,
    };
    if (hideSwatchOnErrorToggle === true) {
      defaultProps = {
        ...defaultProps,
        swatchIndex: index,
        productId,
        updateSwatchesValue,
        isPlp,
      };
    }
    let swatchMarkup = (
      <Toggle
        feature="PLP_IMAGE_SWATCH_LAZY_LOAD_BETA"
        fallback={
          <SwatchImage {...defaultProps} />
        }
      >
        <LazyLoad debounce={false} throttle={0} offset={500}>
          <SwatchImage {...defaultProps} />
        </LazyLoad>
      </Toggle>
    );

    if (moveProductTogglesToList) {
      swatchMarkup = plpImageSwatchLazyLoadBeta
        ? (
          <LazyLoad debounce={false} throttle={0} offset={500}>
            <SwatchImage {...defaultProps} />
          </LazyLoad>
        )
        : (<SwatchImage {...defaultProps} />);
    }

    if (swatchOptimizationToggle === true) {
      swatchMarkup = <SwatchImage {...defaultProps} />;
    }

    return swatchMarkup;
  }

  return (isDisplayableSwatch(options)) && (
    <ul
      className="product-thumbnail__swatches__list"
    >
      {swatches.values.map((swatch, index) => {
        const criteriaForTabletWithModernCss = (isPlpOrSrp)
          ? swatches.values.length === 0
          : shouldHideSwatch(swatches.values.length, SWATCHES_LIMIT_TABLET_MODERN, index);
        const criteriaForMobileWithModernCss = (isPlpOrSrp)
          ? swatches.values.length === 0
          : shouldHideSwatch(swatches.values.length, SWATCHES_LIMIT_MOBILE_MODERN, index);
        const criteriaForDesktopWithModernCss = (isPlpOrSrp)
          ? swatches.values.length === 0
          : shouldHideSwatch(swatches.values.length, SWATCHES_LIMIT_DESKTOP_MODERN, index);
        if (isPlpOrSrp === true && swatch.isVisible === false) return null;
        const classList = classnames(
          'product-thumbnail__swatches__item',
          'product-thumbnail__swatches__item__modern',
          {
            'hide-on-tablet': criteriaForTabletWithModernCss,
            'hide-on-mobile': criteriaForMobileWithModernCss,
            'hide-on-desktop': criteriaForDesktopWithModernCss,
            'product-thumbnail__swatches__item--selected': index === selectedColorSwatchIndex,
          },
          { 'product-thumbnail__swatches__item__modern--selected': index === selectedColorSwatchIndex },
        );

        const swatchName = unescape(get(swatch, 'name', ''));

        return (
          <li
            key={index}
            className={classList}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              changeAlertText(e.currentTarget, swatchName);
              changeSelectedColorSwatchIndex(index, swatch.key);
            }}
            onMouseOver={() => onMouseOverHandler(index)}
            onMouseOut={onMouseOutHandler}
            onTouchStart={(e) => {
              e.stopPropagation();
              e.preventDefault();
              changeAlertText(e.currentTarget, swatchName);
              changeSelectedColorSwatchIndex(index, swatch.key);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === 'Return') {
                e.stopPropagation();
                e.preventDefault();
                changeAlertText(e.currentTarget, swatchName);
                changeSelectedColorSwatchIndex(index, swatch.key);
              }
            }}
            tabIndex={0}
          >
            <div
              className={
                classnames(
                  'product-thumbnail__swatches__item__button',
                  'product-thumbnail__swatches__item__button__modern',
                  {'swatches-style': newProductThumbStyle}
                )
              }
              role="button"
              title={swatchName}
              aria-label={index === selectedColorSwatchIndex ? `${swatchName} selected` : swatchName}
              tabIndex={-1}
            >
              {renderSwatch(swatch,
                swatchName,
                errorMessage,
                index,
                productId,
                updateSwatchesValue,
                isPlp,
                hideSwatchOnErrorToggle,
                swatchOptimizationToggle,
                useCloudinaryImages,
                brand,)}
            </div>
          </li>
        );
      })}
      <li className={swatchList}>
        <Link target="_self" to={url} title={SHOW_MORE_COLORS_AVAILABLE_TEXT} >
          <img src={modernPlusSign} alt={SHOW_MORE_COLORS_AVAILABLE_TEXT} />
        </Link>
      </li>
      <li role="alert" className="sr-only" aria-hidden={true}/>
    </ul>
  );
}

export function shouldHideSwatch(swatchesCount, limit, index) {
  const listGreaterThanLimit = swatchesCount > limit;
  const isLastOrRemaining = index + 1 >= limit;
  return listGreaterThanLimit && isLastOrRemaining;
}

export function shouldHidePlusSign(missingColorSwatchesCount, swatchCount, limit) {
  return (missingColorSwatchesCount === 0 || swatchCount === 0)
    && (swatchCount <= limit);
}

export default ColorSwatches;
