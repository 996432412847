import React, { useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import favoritesSelected from 'assets/images/favorites_selected.svg';
import favoritesOver from 'assets/images/favorites_over.svg';
import noImage from 'assets/images/no-image.jpeg';
import QuickLookButton from 'plpFilters/../Product/components/QuickLookButton/quickLookButton';
import { showQLModalWindow } from '../../../../plp/components/ProductListPage/actions';
import './FavoriteItem.scss';
import CustomLink from 'client/common/components/CustomLink/customLink';

const formatUrl = (url) => {
  if (!url) return noImage;
  const startingIndex = url.startsWith('//') ? 2 : 0;
  return `http://${url.substring(startingIndex)}`;
};

export const DumbFavoriteItem = ({
  item: {
    title,
    designerName,
    retailPrice,
    previousPrice,
    url,
    id,
  },
  showQLModalWindow,
  session,
  getRequestParams,
  isGuest,
}) => {
  const [isFavorited, setIsFavorited] = useState(true);

  const toggleFavorite = async (e) => {
    e.preventDefault();
    setIsFavorited(!isFavorited);
    const { fastlyHost, id: userID, headers } = await getRequestParams(isGuest, session);
    const url = `${fastlyHost}/uca-favorites/v1/${isGuest ? 'guests' : 'accounts'}/${userID}/products`;
    if (isFavorited) {
      axios.delete(`${url}/${id}`, { headers });
    } else {
      axios.post(url, { value: id }, { headers });
    }
  };

  const formatPrice = (price) => (+price).toFixed(2);

  return (
    <CustomLink url={`/p/${id}`} className="favorite_item__link">
      <div className="favorite_item__container">
        <div className="favorite_item__image--container">
          <img className="favorite_item__image" src={formatUrl(url)} alt="product_image" />
          {
            isFavorited
              ? (
                <img
                  className="favorite_item__selected"
                  src={favoritesSelected}
                  alt="favorite_selected"
                  onClick={toggleFavorite}
                />
              )
              : (
                <img
                  className="favorite_item__unselected"
                  src={favoritesOver}
                  alt="favorite_unselected"
                  onClick={toggleFavorite}
                />
              )
          }
        </div>
        <div
          className="favorite_item__quicklook hide-on-mobile"
          onClick={(e) => e.preventDefault()}
        >
          <QuickLookButton
            showQLModalWindow={() => showQLModalWindow(id, `/p/${id}`)}
            isFavorites
          />
        </div>
        {designerName && <span className="favorite_item__brand">{designerName}</span>}
        {title && <span className="favorite_item__product">{title}</span>}
        {retailPrice && (
          <span className="favorite_item__price">
            {previousPrice ? (
              <>
                <span className="original-price">
  $
                  {previousPrice}
                </span>
                <span className="current-price">
  $
                  {formatPrice(retailPrice)}
                </span>
              </>
            ) : `$${formatPrice(retailPrice)}`
          }
          </span>
        )}
      </div>
    </CustomLink>
  );
};

export default connect((state) => (state), { showQLModalWindow })(DumbFavoriteItem);
