import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { MyAccountLeftNavFavIcon } from './MyAccountLeftNavSvgs';
import { normalizeString } from '../OrderStatusLineItem/orderStatusUtils';
import './MyAccountLeftNav.scss';
import CustomLink from 'client/common/components/CustomLink/customLink';

const MyAccountLeftNav = ({
  name, active, orderHistoryToggle, specialOffersToggle,myStylePrefToggle
}) => {
  return (
    <div className="nav-links">
      <ul className="nav">
        {name ? (
          <li>
            <span className="myGlobalSignedIn" tabIndex="0">
              {'Hello '}
              {normalizeString(name)}
              &nbsp;
            </span>
          </li>
        ) : (
          <li>
            <CustomLink url="/login">
              <span className="myGlobalSignedIn" tabIndex="0">
                Sign In / Register
              </span>
            </CustomLink>
          </li>
        )}
        <li className="myfavorites-border">
          <CustomLink className="changeOver myBrand" url="/myNM.jsp?itemId=mynm">MyNM Homepage</CustomLink>
        </li>
        <li className="myFav">
          <div id="myNMFavorites" tabIndex="0">
            <span>My Favorites</span>
            &nbsp;
            {MyAccountLeftNavFavIcon}
          </div>
        </li>
        <li className="myFav">
          <CustomLink
            id="myFavNavFI"
            className={active === 'myFavNavFI' ? 'active' : 'changeOver'}
            url="/myfavorites/myFavoriteItems.jsp?view=favItems&forOmniLN=1&favin=1"
          >
            Favorite Items
          </CustomLink>
        </li>
        {myStylePrefToggle ? (
          <li className="myFav">
            <CustomLink id="myFavNavMSP" url="/my/preferences/stylepreferences" className={active === 'myFavNavMSP' ? 'active' : 'changeOver'}>My Style Preferences</CustomLink>
          </li>
        ) :
          (<li className="myFav">
            <CustomLink id="myFavNavFD" className="changeOver" url="/myfavorites/myFavoriteDesigners.jsp?view=favd&forOmniLN=1">Favorite Designers</CustomLink>
          </li>)}
        <li className="myFavStore">
          <CustomLink id="myFavNavFS" className="changeOver" url="/myfavorites/myFavorites.jsp?view=favs&forOmniLN=1">Favorite Store</CustomLink>
        </li>
        {!myStylePrefToggle && (<li className="myFavSizes myfavorites-border">
          <CustomLink id="myFavNavFSI" className="changeOver" url="/myfavorites/myFavoritesSizes.jsp?view=favsizes">Favorite Sizes</CustomLink>
        </li>)}
        <li className="myFav">
          <div id="myNMFavAcct" tabIndex="0">
            <span>My Account</span>
          </div>
        </li>
        <li className="myFav">
          <CustomLink
            id="myAcctNavAO"
            className={active === 'myAcctNavAO' ? 'active' : 'changeOver'}
            url={active === 'myAcctNavAO' ? '/accountoverview' : `${NMConfig.ACCOUNT_PAGE_URL}?fromLN=myNMNav`}
          >
            Account Overview
          </CustomLink>
        </li>
        <li className="myFav">
          <CustomLink
            id="myAcctNavOH"
            className={active === 'myAcctNavOH' ? 'active' : 'changeOver'}
            url={active === 'myAcctNavOH' || orderHistoryToggle ? '/order-history' : `${NMConfig.ACCOUNT_PAGE_URL}?fromLN=myNMNav&view=history&list=default&forOmniLN=1`}
          >
            Order History
          </CustomLink>
        </li>
        <li className="myFav">
          <CustomLink id="myAcctNavAB" className="changeOver" url={`${NMConfig.ACCOUNT_PAGE_URL}?fromLN=myNMNav&view=address&forOmniLN=1`}>Address Book</CustomLink>
        </li>
        <li className="myFav">
          <CustomLink id="myAcctNavPI" className="changeOver" url={`${NMConfig.ACCOUNT_PAGE_URL}?fromLN=myNMNav&view=pay&forOmniLN=1`}>Payment Information</CustomLink>
        </li>
        {
          specialOffersToggle
          && (
          <li className="myFav">
            <CustomLink id="myNMSpecialOffers" className={active === 'myNMSpecialOffers' ? 'active' : 'changeOver'} url="/specialoffers">Special Offers</CustomLink>
          </li>
          )
        }
        <li className="myFav">
          <CustomLink id="myNMCreditCard" className="changeOver" url="/c/NM/NM-Credit-Card-cat18480743">NM Credit Card</CustomLink>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orderHistoryToggle: get(state, 'toggles.DT_ORDER_HISTORY', false),
    specialOffersToggle: get(state, 'toggles.WKY_LINK_TO_SPECIAL_OFFERS_PAGE', false),
    myStylePrefToggle: get(state, 'toggles.MY_STYLE_PREFERENCES', false)
  };
};

export default connect(mapStateToProps)(MyAccountLeftNav);
