import React from 'react';
import Slider from 'react-slick';
import './visualNavSlider.scss';
import CustomLink from 'client/common/components/CustomLink/customLink';

export const VisualNavSlider = (props) => {
  const { data } = props;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    lazyLoad: true,
  };

  return (
    <nav className="visual-navigation">
      <Slider {...settings}>
        {Array.isArray(data)
          && data.map((item) => (
            <div key={item.label} className="item">
              <CustomLink url={item.url}>
                <img src={item.image} title={item.label} alt={item.label} />
                <div>{item.label}</div>
              </CustomLink>
            </div>
          ))}
      </Slider>
    </nav>
  );
};
