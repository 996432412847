import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  url: PropTypes.string,
  openOnNewTab: PropTypes.boolean
};

const CustomLink = props => {
  const { url, openOnNewTab = false, className, children, ...rest } = props;

  if (url) {
    return (
      <a
        {...rest}
        href={url}
        target={openOnNewTab ? "_blank" : "_self"}
        className={className || ""}
      >
        {children}
      </a>
    );
  }

  return (
    <a
      {...rest}
      className={className || ""}
    >
      {children || ""}
    </a>
  );
};

CustomLink.propTypes = propTypes;
export default CustomLink;
