import React from 'react';
import CustomLink from 'client/common/components/CustomLink/customLink';

const SiloImage = ({
  siloTitle, url, imageUrl, name, imageNum, countryCode,
}) => {
  let analyticsFormatedUrl = url;
  if (url) {
    analyticsFormatedUrl = url.includes('?')
      ? `${url}&icid=Silo_${imageNum}_${siloTitle}_${name}_${countryCode}`
      : `${url}?icid=Silo_${imageNum}_${name}_${countryCode}`;
  }

  return (
    <CustomLink className="Silo__image_wrapper_no_grid" url={analyticsFormatedUrl}>
      <img src={imageUrl} alt={name} />
      <h2>{name}</h2>
    </CustomLink>
  );
};

export default SiloImage;
