import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import isUndefined from 'lodash/isUndefined';
import React from 'react';
import '../layoutStyles.scss';
// eslint-disable-next-line import/no-cycle
import {
  getAdditionalStyles,
  getAlignmentClass,
  getLayoutAsComponent,
  getSpacing,
  MOBILE_DEVICE,
  TABLET_DEVICE,
  DESKTOP_DEVICE,
  getTrackingTags,
  concatTrackTagsObjects,
  iosBgSizeCoverFix,
  checkForICIDAndAddTags,
  optimizeImageUrl,
} from 'cms/components/utils';
// eslint-disable-next-line import/no-cycle
import EditorialSiloV2 from 'cms/components/editorialSilo/editorialSilo';
import DynamicHorizontalRail from 'cms/components/dynamicHorizontalRail/dynamicHorizontalRail';
// eslint-disable-next-line import/no-cycle
import RenderContentItem from './renderContentItem';
import { ABTEST_FACET_BUTTONS } from 'client/common/actions/actions-page';
import CustomLink from 'client/common/components/CustomLink/customLink';
// eslint-disable-next-line import/no-cycle

const TWO_COLUMN_PARALLAX = 'Two Column Parallax';
const THREE_COLUMN_PARALLAX = 'Three Column Parallax';
const MULTI_CAROUSEL = 'Multiple Product Carousels';
const MULTI_GRID = 'Multiple Product Grids';

const removeLayoutsDrafts = (subLayout) => {
  remove(subLayout, (item) => !item.fields);
};

const cdnHosts = global.cloudinary?.cdnHosts || {};

// eslint-disable-next-line max-len
const renderPlacement = (subLayouts, placement, isMobilePhone, isTablet, isDesktop, isSpacingToggle, imageOptimizationToggle, cssrgbToggle, ttags, neverLazyload,
  productListPage, page, session, mobileFacetButtonsAbTest, boutiquePage, womenApparelCat, founditVisualNav = false, founditVisualNavTest = false) => {
  return subLayouts.map((item) => {
    const pagePlacement = get(item, 'fields.placement');

    if (pagePlacement && pagePlacement === placement) {
      return (
        <RenderLayout
          key={item.sys.id}
          cmsLayout={item}
          isMobilePhone={isMobilePhone}
          isTablet={isTablet}
          isDesktop={isDesktop}
          isSpacingToggle={isSpacingToggle}
          imageOptimizationToggle={imageOptimizationToggle}
          cssrgbToggle={cssrgbToggle}
          trackTags={ttags}
          neverLazyload={neverLazyload}
          productListPage={productListPage}
          page={page}
          session={session}
          mobileFacetButtonsAbTest={mobileFacetButtonsAbTest}
          boutiquePage={boutiquePage}
          womenApparelCat={womenApparelCat}
          founditVisualNav={founditVisualNav}
          founditVisualNavTest={founditVisualNavTest}
        />
      );
    }
    return null;
  });
};

// eslint-disable-next-line max-len
const renderSubLayouts = (subLayouts, isMobilePhone, isTablet, isDesktop, isSpacingToggle, imageOptimizationToggle, cssrgbToggle, ttags, neverLazyload,
  productListPage, page, session) => (
  <>
    {
      subLayouts.map((item) => (
        <RenderLayout
          key={item.sys.id}
          cmsLayout={item}
          isMobilePhone={isMobilePhone}
          isTablet={isTablet}
          isDesktop={isDesktop}
          isSpacingToggle={isSpacingToggle}
          imageOptimizationToggle={imageOptimizationToggle}
          cssrgbToggle={cssrgbToggle}
          trackTags={ttags}
          neverLazyload={neverLazyload}
          productListPage={productListPage}
          page={page}
          session={session}
        />
      ))
    }
  </>
);

const renderContentLayout = (contentItems, isMobilePhone, ttags, neverLazyload,
  productListPage, page, session, mobileFacetButtonsAbTest=false, boutiquePage, womenApparelCat, founditVisualNav = false, founditVisualNavTest = false) => (
  <>
    {
      contentItems.map((item, index) => {
      const hideContent = (item.contentType === 'visualNav' && isMobilePhone && mobileFacetButtonsAbTest && !boutiquePage && !womenApparelCat);
      const dontShowContentfulVisNav = item.contentType === 'visualNav' && founditVisualNavTest && !founditVisualNav; 

      if(!hideContent && !dontShowContentfulVisNav){
        return (
          <div className="component" key={index}>
            <RenderContentItem
              cmsContentItem={item}
              isMobilePhone={isMobilePhone}
              trackTags={ttags}
              key={item.sys.id}
              neverLazyload={neverLazyload}
              productListPage={productListPage}
              page={page}
              session={session}
            />
          </div>
        )
      }
      return null;
      })
    }
  </>
);

const wrapLayoutWithLink = (url, openLinkInNewTab, content) => {
  return (
    <CustomLink
      className="wrapper-link"
      url={url}
      openOnNewTab={openLinkInNewTab}
    >
      {content}
    </CustomLink>
  );
};

const RenderLayout = ({
  cmsLayout = {},
  placement,
  isMobilePhone,
  isTablet,
  isDesktop,
  isSpacingToggle,
  imageOptimizationToggle,
  cssrgbToggle,
  trackTags = {},
  neverLazyload = false,
  productListPage,
  page,
  session,
  mobileFacetButtonsAbTest,
  boutiquePage,
  womenApparelCat,
  founditVisualNav = false,
  founditVisualNavTest = false
}) => {
  const cmsContentFields = get(cmsLayout, 'fields', {});
  const parallaxEffectType = cmsContentFields.parallaxEffect;
  const verticalSpacingDesktop = get(cmsLayout, 'fields.verticalSpacingDesktop', '');
  const verticalSpacingTablet = get(cmsLayout, 'fields.verticalSpacingTablet', '');
  const verticalSpacingMobile = get(cmsLayout, 'fields.verticalSpacingMobile', '');
  const horizontalSpacingDesktop = get(cmsLayout, 'fields.horizontalSpacingDesktop', '');
  const horizontalSpacingTablet = get(cmsLayout, 'fields.horizontalSpacingTablet', '');
  const horizontalSpacingMobile = get(cmsLayout, 'fields.horizontalSpacingMobile', '');
  const topSpacingDesktop = get(cmsLayout, 'fields.topSpacingDesktop', '');
  const topSpacingTablet = get(cmsLayout, 'fields.topSpacingTablet', '');
  const topSpacingMobile = get(cmsLayout, 'fields.topSpacingMobile', '');
  const link = get(cmsContentFields, 'link', '');
  const openLinkInNewTab = get(cmsContentFields, 'openLinkInNewTab', false);
  const isModalContent = !openLinkInNewTab && !isUndefined(openLinkInNewTab);

  const widthAttribute = isMobilePhone
    ? cmsContentFields.mobileWidth : cmsContentFields.desktopWidth;

  const layoutWidth = widthAttribute ? `width-${widthAttribute}` : 'width-100';
  const deviceType = (isMobilePhone && MOBILE_DEVICE)
    || (isTablet && TABLET_DEVICE) || DESKTOP_DEVICE;
  const horizontalSpacingType = (isMobilePhone && horizontalSpacingMobile)
    || (isTablet && horizontalSpacingTablet) || (isDesktop && horizontalSpacingDesktop);
  const verticalSpacingType = (isMobilePhone && verticalSpacingMobile)
    || (isTablet && verticalSpacingTablet) || (isDesktop && verticalSpacingDesktop);
  const topSpacingType = (isMobilePhone && topSpacingMobile)
    || (isTablet && topSpacingTablet) || (isDesktop && topSpacingDesktop);
  const verticalSpacingStyles = isSpacingToggle && getSpacing(deviceType, verticalSpacingType);
  const horizontalSpacingStyles = isSpacingToggle
    && getSpacing(deviceType, horizontalSpacingType, layoutWidth);
  const topSpacingStyles = getSpacing(deviceType, topSpacingType);
  const verticalStyles = isSpacingToggle && verticalSpacingStyles ? `reset-spacing ${verticalSpacingStyles}` : '';
  const horizontalStyles = isSpacingToggle && horizontalSpacingStyles ? horizontalSpacingStyles : '';
  const topStyles = topSpacingStyles ? `${topSpacingStyles}-top` : '';

  switch (cmsLayout.contentType) {
    case 'frame': {
      const subLayout = get(cmsContentFields, 'l1Layouts', []);
      const frameTrackTags = getTrackingTags(get(cmsContentFields, 'trackingTags', []));

      return (
        <div className="cms-frame">
          {/* eslint-disable-next-line max-len */}
          {renderPlacement(subLayout, placement, isMobilePhone, isTablet, isDesktop, isSpacingToggle, imageOptimizationToggle, cssrgbToggle, frameTrackTags, neverLazyload,
            productListPage, page, session, mobileFacetButtonsAbTest, boutiquePage, womenApparelCat, founditVisualNav, founditVisualNavTest)}
        </div>
      );
    }
    case 'l1Layout': {
      let subLayout = get(cmsContentFields, 'l2Layout', []);
      let contentItem = get(cmsContentFields, 'components', []);
      const nestedFrames = get(cmsContentFields, 'frames', []);
      const backgroundImageProps = get(cmsContentFields, 'background[0].fields', {});
      // those will be arrays here, or undefined if it is not set
      const { mobileImage, desktopImage } = backgroundImageProps;
      const imageSrc = isMobilePhone && mobileImage ? mobileImage : desktopImage;
      const cdnImageSrc = Object.entries(cdnHosts).reduce(
        (backgroundImageUrl, [cloudinaryHost, nmHost]) => backgroundImageUrl
          .replace(cloudinaryHost, nmHost),
        imageSrc,
      );
      const l1TrackingTags = getTrackingTags(get(cmsContentFields, 'trackingTags', []));
      const combinedL1TrackingTags = concatTrackTagsObjects(trackTags, l1TrackingTags);
      let backgroundImageUrl = '';
      const reverseDirectionOnMobile = get(cmsContentFields, 'reverseDirectionOnMobile', false);
      if (isMobilePhone && reverseDirectionOnMobile) {
        contentItem = [...contentItem].reverse();
        subLayout = [...subLayout].reverse();
      }

      if (cdnImageSrc && cdnImageSrc[0] && cdnImageSrc[0].secure_url) {
        backgroundImageUrl = cdnImageSrc[0].secure_url;

        if (cssrgbToggle) {
          backgroundImageUrl = backgroundImageUrl.replace('q_auto', 'cs_srgb,q_auto');
        }

        if (imageOptimizationToggle && isMobilePhone) {
          backgroundImageUrl = optimizeImageUrl(backgroundImageUrl);
        }
      }

      return (
        <>
          {backgroundImageUrl && <style>{iosBgSizeCoverFix(backgroundImageUrl)}</style>}
          <div
            className={`l1-layout ${layoutWidth}`}
          >
            {/* eslint-disable-next-line max-len */}
            {!!subLayout.length && renderSubLayouts(subLayout, isMobilePhone, isTablet, isDesktop, isSpacingToggle, imageOptimizationToggle, cssrgbToggle, combinedL1TrackingTags, neverLazyload,
              productListPage, page, session)}
            {/* eslint-disable-next-line max-len */}
            {!subLayout.length && renderContentLayout(contentItem, isMobilePhone, combinedL1TrackingTags, neverLazyload,
              productListPage, page, session, mobileFacetButtonsAbTest, boutiquePage, womenApparelCat, founditVisualNav, founditVisualNavTest)}
            {!!nestedFrames.length && <EditorialSiloV2 />}
          </div>
        </>
      );
    }
    case 'l2Layout':
    case 'l3Layout':
    case 'l4Layout': {
      const type = cmsLayout.contentType.toLowerCase();
      const subLayoutName = `l${+cmsLayout.contentType.substring(1, 2) + 1}Layout`;
      let subLayout = get(cmsContentFields, subLayoutName, []);
      let contentItem = get(cmsContentFields, 'components', []);
      const layoutAsComponent = get(cmsContentFields, 'layoutAsComponent', '');
      const isLayoutFullBleed = get(cmsContentFields, 'isFullBleedLayout', false);
      const isAnchorPoint = get(cmsContentFields, 'anchorPoint', '');
      const anchorBackground = get(cmsContentFields, 'backgroundColor', '');
      const overlapBorderColor = get(cmsContentFields, 'borderColor', '');
      const overlapBorderSize = get(cmsContentFields, 'borderSize', '');
      const verticalOverlapPercent = get(cmsContentFields, 'verticalOverlapPercent', '');
      const horizontalOverlapPercent = get(cmsContentFields, 'horizontalOverlapPercent', '');
      const anchorPointAsId = isAnchorPoint ? { id: isAnchorPoint } : {};
      const l24TrackingTags = getTrackingTags(get(cmsContentFields, 'trackingTags', []));
      const combinedL24TrackingTags = concatTrackTagsObjects(trackTags, l24TrackingTags);
      const reverseDirectionOnMobile = get(cmsContentFields, 'reverseDirectionOnMobile', false);
      const verticallyAlign = get(cmsContentFields, 'verticallyAlign', '');
      const buttonStyle = get(cmsContentFields, 'buttonStyle', '');
      const backgroundImage = get(cmsContentFields, 'backgroundImage[0].secure_url', '');

      removeLayoutsDrafts(subLayout);

      const subLayoutWidth = subLayout.reduce(
        (acc, item) => acc + parseInt(
          isMobilePhone ? item.fields.mobileWidth : item.fields.desktopWidth,
          10
        ),
        0
      );

      const setGrid = (subLayout.length !== 1) ? subLayout.reduce(
        (reducer, item) => (reducer || !!item.fields.components), false
      )
        : false;

      const twoColumnTypeStyle = parallaxEffectType === TWO_COLUMN_PARALLAX && 'two-column-parallax';
      const threeColumnTypeStyle = parallaxEffectType === THREE_COLUMN_PARALLAX && 'three-column-parallax';
      const multiProductCarouselStyle = parallaxEffectType === MULTI_CAROUSEL;
      const multiProductGridStyle = parallaxEffectType === MULTI_GRID;

      let multiCarousel;
      let firstCarouselIndex;
      const contentItemsWithCarousel = [];
      if (multiProductCarouselStyle) {
        const contentItemId = get(cmsLayout, 'sys.id', '');

        multiCarousel = contentItem.filter((obj, index) => {
          if (obj.contentType === 'productCarouselContentAsset') {
            firstCarouselIndex = firstCarouselIndex ?? index;
            return true;
          }
          return false;
        });
        const multiCarouselAsset = {
          contentType: 'multiCarouselContentAsset',
          content: multiCarousel,
          sys: {
            id: contentItemId,
          },
        };
        contentItemsWithCarousel.splice(firstCarouselIndex, 0, multiCarouselAsset);
        contentItem = contentItemsWithCarousel;
      }

      let multiGrid;
      let firstGridIndex;
      const contentItemsWithGrid = [];
      if (multiProductGridStyle) {
        const contentItemId = get(cmsLayout, 'sys.id', '');

        multiGrid = contentItem.filter((obj, index) => {
          if (obj.contentType === 'productComponent') {
            firstGridIndex = firstGridIndex ?? index;
            return true;
          }
          return false;
        });
        const multiGridAsset = {
          contentType: 'multiGridContentAsset',
          content: multiGrid,
          sys: {
            id: contentItemId,
          },
        };
        contentItemsWithGrid.splice(firstGridIndex, 0, multiGridAsset);
        contentItem = contentItemsWithGrid;
      }

      const parallaxStyles = (!isMobilePhone && !!parallaxEffectType)
        && (twoColumnTypeStyle || threeColumnTypeStyle);
      const isDynamicRail = parallaxEffectType === 'Dynamic Horizontal Content Rail';
      const layoutClassName = `${type.substring(0, 2)}-${type.substring(2, type.length)} ${setGrid ? 'layout-space' : 'layout-center'} ${layoutWidth} ${parallaxStyles || ''} ${isLayoutFullBleed ? 'l2-full-bleed-layout' : ''} ${verticalStyles} ${horizontalStyles} ${topStyles}`;
      const additionalStyles = /l[23]layout/.test(type) ? getAdditionalStyles(cmsContentFields, backgroundImage) : {};
      const alignmentClass = getAlignmentClass(cmsContentFields);
      const sublayoutMultirowClass = (subLayoutWidth > 100) && !isSpacingToggle ? 'sublayout-multirow' : '';
      if (isMobilePhone && reverseDirectionOnMobile) {
        contentItem = [...contentItem].reverse();
        subLayout = [...subLayout].reverse();
      }

      const linkTo = checkForICIDAndAddTags(link, trackTags, l24TrackingTags, isModalContent);

      const content = (
        <>
          {/* eslint-disable-next-line max-len */}
          {layoutAsComponent && getLayoutAsComponent(layoutAsComponent, contentItem, anchorBackground, combinedL24TrackingTags, overlapBorderColor, overlapBorderSize, verticalOverlapPercent, horizontalOverlapPercent, buttonStyle)}
          {/* eslint-disable-next-line max-len */}
          {!!subLayout.length && !isDynamicRail && renderSubLayouts(subLayout, isMobilePhone, isTablet, isDesktop, isSpacingToggle, imageOptimizationToggle, cssrgbToggle, combinedL24TrackingTags, neverLazyload,
            productListPage, page, session)}
          {isDynamicRail
            && (
              <DynamicHorizontalRail verticallyAlign={verticallyAlign} hasBackgroundImage={!isEmpty(backgroundImage)}>
                {/* eslint-disable-next-line max-len */}
                {renderSubLayouts(subLayout, isMobilePhone, isTablet, isDesktop, isSpacingToggle, imageOptimizationToggle, cssrgbToggle, combinedL24TrackingTags,
                  productListPage, page, session)}
              </DynamicHorizontalRail>
            )
          }
          {/* eslint-disable-next-line max-len */}
          {!subLayout.length && !layoutAsComponent && renderContentLayout(contentItem, isMobilePhone, combinedL24TrackingTags, neverLazyload,
            productListPage, page, session, founditVisualNav, founditVisualNavTest)}
        </>
      );

      return (
        <div {...anchorPointAsId} className={`${layoutClassName} ${alignmentClass} ${sublayoutMultirowClass}`} style={{ ...additionalStyles }}>
          {linkTo ? wrapLayoutWithLink(linkTo, openLinkInNewTab, content) : content}
        </div>
      );
    }
    case 'l5Layout': {
      let contentItem = get(cmsContentFields, 'components', []);
      const l5TrackingTags = getTrackingTags(get(cmsContentFields, 'trackingTags', []));
      const combinedL5TrackingTags = concatTrackTagsObjects(trackTags, l5TrackingTags);
      const reverseDirectionOnMobile = get(cmsContentFields, 'reverseDirectionOnMobile', false);
      if (isMobilePhone && reverseDirectionOnMobile) {
        contentItem = [...contentItem].reverse();
      }

      const linkTo = checkForICIDAndAddTags(link, trackTags, l5TrackingTags, isModalContent);

      const content = renderContentLayout(
        contentItem, isMobilePhone, combinedL5TrackingTags, neverLazyload, productListPage, page, session
      );

      return (
        <div className={`l5-layout ${layoutWidth} ${verticalStyles} ${horizontalStyles} ${topStyles}`}>
          {linkTo ? wrapLayoutWithLink(linkTo, openLinkInNewTab, content) : content}
        </div>
      );
    }

    case 'components': {
      return (
        <>
          <div className="component">
            <RenderContentItem
              cmsContentItem={cmsContentFields}
              isMobilePhone
              key={cmsContentFields.sys.id}
              neverLazyload={neverLazyload}
            />
          </div>
        </>
      );
    }
    default: {
      return (
        <div />
      );
    }
  }
};

const isBoutiquePLP = (state) => {
  const boutiqueChild = get(state, 'templates.templateDetails.boutiqueChild', false)
      || get(state, 'productListPage.products.boutiqueChild', false);
  const boutiqueFlag = get(state, 'utag.productListPage.page_type') === 'boutique';
  return boutiqueChild || boutiqueFlag;
}

const isWomenApparelCat = (state) => {
  const defaultPath = get(state, 'templates.templateDetails.defaultPath', '');
  const navPath = get(state, 'page.location.query.navpath', '');
  return (defaultPath || navPath || "").includes(NMConfig.WOMEN_APPAREL_CAT_ID) || false;
}

const mapStateToProps = (state) => ({
  isMobilePhone: get(state, 'device.isMobilePhone', false),
  isTablet: get(state, 'device.isTablet', false),
  isDesktop: get(state, 'device.isDesktop', false),
  isSpacingToggle: get(state, 'toggles.SPACING_FOR_NEW_CONTENT_MODEL', false),
  imageOptimizationToggle: get(state, 'toggles.IMAGE_OPTIMIZATIONS', false),
  cssrgbToggle: get(state, 'toggles.CS_SRGB_EDITORIAL', false),
  productListPage: get(state, 'productListPage', null),
  page: get(state, 'page', {}),
  session: get(state, 'session', {}),
  mobileFacetButtonsAbTest: get(state, `abTestsOpt.${ABTEST_FACET_BUTTONS}.variation`, 'a') === 'b',
  boutiquePage: isBoutiquePLP(state),
  womenApparelCat: isWomenApparelCat(state),
});

export default connect(mapStateToProps)(RenderLayout);
