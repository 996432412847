/* eslint import/no-cycle: 0 */
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import classNames from 'classnames';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import React from 'react';
import { connect } from 'react-redux';
import RenderContentItem from 'cms/components/renderContentItem';
import {
  checkForICIDAndAddTags, concatTrackTagsObjects, getTrackingTags, renderMultiDeviceText,
} from '../utils';
import EntryHyperlink from './entryHyperlink';
import {
  setDynamicRailLinkHovered,
  setDynamicRailLinkNotHovered,
} from '../../actions/index';
import './richText.scss';
import CustomLink from 'client/common/components/CustomLink/customLink';

const CN = 'rich-text';
const CN_LIST = 'list';
const ROMAN_NUMERALS_TYPE = 'Roman Numerals';
const CN_BN = 'rich-text-button-Nav';

export function DumbRichText(props) {
  const {
    cmsContentItem = {},
    isMobilePhone,
    trackTags: propsTrackTags = {},
    isDesktop,
    isDynamicRailHovered,
    setDynamicRailLinkHovered,
    setDynamicRailLinkNotHovered,
    abtButtonNavToggle = false,
    isSelected = false,
    isRomieFont
  } = props;
  const fields = get(cmsContentItem, 'fields', {});
  const {
    backgroundColor,
    buttonStyle,
    buttonBackgroundColor,
    desktopText,
    mobileText,
    tabletText,
    openLinkInNewTab,
    textColor,
    mobileTextColor,
    textJustification,
    mobileTextJustification,
    textPadding,
    listType,
    listTypeMobile,
    paragraphDisplay,
    trackingTags: ownTrackTags = [],
    fullWidthModal,
  } = fields;

  const styles = {
    backgroundColor,
    color: isMobilePhone && mobileTextColor ? mobileTextColor : textColor,
    padding: textPadding && `${textPadding}px`,
    textAlign: isMobilePhone && mobileTextJustification
      ? mobileTextJustification
      : textJustification,
  };

  const onMouseOverLink = () => {
    if (isDynamicRailHovered) {
      setDynamicRailLinkHovered();
    }
  };

  const onMouseLeaveLink = () => {
    if (isDynamicRailHovered) {
      setDynamicRailLinkNotHovered();
    }
  };

  if (buttonStyle && buttonStyle === 'underlined') {
    styles.borderColor = (textColor || '#000');
  }

  const type = isMobilePhone ? listTypeMobile : listType;
  const listTypeStyles = type === ROMAN_NUMERALS_TYPE ? `${CN_LIST}-roman` : CN_LIST;
  const paragraphDisplayStyle = paragraphDisplay ? `p-${paragraphDisplay}` : null;
  const componentClassNames = classNames(
    CN,
    { [`${CN}--${buttonStyle}`]: buttonStyle },
    { [`${CN}--${buttonStyle}--${buttonBackgroundColor}`]: (buttonStyle === 'button' || buttonStyle === 'button-hovered') && buttonBackgroundColor },
    listTypeStyles,
    { [`align-${textJustification?.toLowerCase()}`]: textJustification },
    paragraphDisplayStyle,
    { 'enable-hovered-view': !isDesktop },
    { 'romie-regular': isRomieFont },
  );

  const componentBNClassNames = classNames(CN_BN, { 'selectedNav': isSelected });
  
  const options = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <EntryHyperlink
          node={node}
          fullWidthModal={fullWidthModal}
          onMouseOver={onMouseOverLink}
          onMouseLeave={onMouseLeaveLink}
        >
          {children}
        </EntryHyperlink>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        const markType = node.content[0]?.marks[0]?.type;
        const isModalContent = !openLinkInNewTab && !isUndefined(openLinkInNewTab);
        let linkTo = checkForICIDAndAddTags(node.data.uri,
          propsTrackTags,
          ownTrackTags,
          isModalContent);
          
        if (markType === 'underline') {
          return (
            <CustomLink
              className="link"
              url={linkTo}
              openOnNewTab={openLinkInNewTab}
              onMouseOver={onMouseOverLink}
              onMouseLeave={onMouseLeaveLink}
            >
              {children}
            </CustomLink>
          );
        }

        return (
          <CustomLink
            url={linkTo}
            openOnNewTab={openLinkInNewTab}
            onMouseOver={onMouseOverLink}
            onMouseLeave={onMouseLeaveLink}
          >
            {children}
          </CustomLink>
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const embeddedEntry = node?.data?.target;
        // eslint-disable-next-line max-len
        const combinedTrackTags = concatTrackTagsObjects(propsTrackTags, getTrackingTags(ownTrackTags));

        return <RenderContentItem trackTags={combinedTrackTags} cmsContentItem={embeddedEntry} />;
      },
    },
  };

  return renderMultiDeviceText(
    ({ children }) => <div className={abtButtonNavToggle ? componentBNClassNames : componentClassNames} style={styles}>{children}</div>,
    {
      isMobilePhone,
      desktopText,
      tabletText,
      mobileText,
      transformText: (content) => documentToReactComponents(content, options),
    }
  );
}

const mapDispatchToProps = ({
  setDynamicRailLinkHovered,
  setDynamicRailLinkNotHovered,
});

const mapStateToProps = (state) => ({
  isMobilePhone: get(state, 'device.isMobilePhone'),
  isDesktop: get(state, 'device.isDesktop'),
  isDynamicRailHovered: get(state, 'cms.dynamicRail.hovered'),
  isRomieFont: get(state, 'toggles.ROMIE_FONT', false),
});

export default connect(mapStateToProps, mapDispatchToProps)(DumbRichText);
