import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { unescape } from 'he';
import DropDown from 'shared/components/DropDown/dropDown';
import { SORT_BY_TEXT, IN_STORE } from 'plp/constants';
import { getParams } from 'client-utils/utilities-router';
import drop from 'lodash/drop';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import classnames from 'classnames';
import { renderFormattedNumber } from 'client-utils/utilities-price';
import { seoSelectedFilter } from 'client-utils/utilities-seoFacetUtag';
import { hasContentType, checkCmsBannerPosition } from 'client-utils/utilities-cms';
import ConnectedCSPToggle from 'shared/components/CSPToggle/CSPToggle';
import { ScrollMenu } from 'storefront/components/Navigation/ScrollMenu/ScrollMenu';
import Pagination from '../Pagination/pagination';
import MobileSort from './components/MobileSort/mobileSort';
import MobileOption from './components/MobileOption/mobileOption';
import ConnectedMobileFacetedFilter from './components/MobileFacetedFilter/mobileFacetedFilter';
import NmSeo from '../../../NmSeo/NmSeo';
import { isBoutiquePLP, isWomenApparelCat } from '../../../../productListPage';
import { getSelectedFilterCountMobile , getSelectedFilterCount } from '../Filters/filters';
import { HeaderPosition } from './components/atoms/HeaderPosition/HeaderPosition';
import { VisualNavSlider } from '../VisualNavSlider/visualNavSlider';
import { isTypeAheadOn, isCollapsedSearchBarOn } from 'srp/utils/srpUtils';
import './ProductListHeader.scss';
import './components/atoms/HeaderPosition/HeaderPosition.scss';
import MobileFacetFilterButtons from './components/MobileFacetButtons/mobileFacetButtons';
import { Divider } from 'aClientComponents/index';
import { ABTEST_FACET_BUTTONS, ABTEST_FOUNDIT_VN, ABTEST_MOBILE_FILTER_OPTIMIZATION } from 'client/common/actions/actions-page';

export const modifyBreadCrumbs = (breadcrumbs, navPath) => {
  const strippedIds = drop(navPath.split('_'));
  if (strippedIds.length > 0 && breadcrumbs.length > strippedIds.length) {
    const tempBreadcrumbs = breadcrumbs.filter((item) => {
      return strippedIds.includes(item.id);
    });
    return tempBreadcrumbs;
  }
  return breadcrumbs;
};

export const getCategoryTitle = (classNames, boutiqueChild, title, designerName, isDomestic, customHeaderCategories, catId, subTitle, nmAwardToggle, nmSeoCopyToggle, titleWidth) => {
  const isBoutiqueChild = boutiqueChild || false;
  const categoryName = unescape(title || '');
  const designer = unescape(designerName || '');
  let fullCategoryName = isBoutiqueChild && categoryName !== designer && !categoryName.includes(designer)
    ? `${designer} ${categoryName}` : categoryName;
  const customHeaderTitle = customHeaderCategories?.[catId];
  if (isDomestic && customHeaderTitle) {
    fullCategoryName = customHeaderTitle;
  }

  const titleStyles = (nmSeoCopyToggle && titleWidth > 0) ? { width: `${titleWidth}px` } : {}
  return (
    <>
      <h1 style={titleStyles} className={classnames(classNames, {
        'nm-award-subtitle': (!isNull(subTitle) && nmAwardToggle),
        'single-copy-line': nmSeoCopyToggle
        }
      )} >{fullCategoryName}</h1>
      {!isNull(subTitle) && nmAwardToggle && (
        <h5 className="product-list__header__subtitle">{subTitle}</h5>
      )
      }
    </>
  )
};

export const getNmSeoContent = (seoCopyContent, isMobilePhone, hasVisualNavOrPromoBanner, setSeoWidth) => {
  if (!isEmpty(seoCopyContent)) {
    return (
      <NmSeo
        setSeoWidth={setSeoWidth}
        content={seoCopyContent}
        isMobilePhone={isMobilePhone}
        hasVisualNavOrPromoBanner={hasVisualNavOrPromoBanner}
      />
    );
  }
  return null;
};
export class ProductListHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleWidth: 0
    }
    this.onSortChange = this.props.onChange;
    this.pageYOffset = 0;
    this.handleVisualNavBackAction = this.handleVisualNavBackAction.bind(this);
    this.rendernavPrevTitle = this.rendernavPrevTitle.bind(this);
  }

  handleVisualNavBackAction(url) {
    window.location.href = url;
    return 0;
  }

  rendernavPrevTitle() {
    const { prevCategory } = this.props;
    return prevCategory.nameForMobile;
  }

  setSeoWidth = (width) => {
    this.setState({ titleWidth: width });
  }

  render() {
    const {
      isStickyHeaderActive,
      isSearchExposed,
      isTop,
      isBottom,
      isMobilePhone,
      visualNavSliderToggle,
      visualNavItems,
      sortOptions,
      leftNavigation,
      leftNavId,
      boutiqueChild,
      title,
      designerName,
      sysfacetMobileToggle,
      newVisNavToggle,
      brand,
      seoToggle,
      currentNavPath,
      filterSortToggle,
      abtestFilterSortToggle,
      abTestsFilterSortValue,
      isInternational,
      isDomestic,
      customHeaderCategories,
      catId,
      isInStoreFacetAvailable,
      abtButtonNavToggle,
      nmAwardToggle,
      subTitle,
      boutiqueFlag,
      typeAheadExperience,
      mobileFacetButtonsAbTest,
      isRebrandNMToggleOn,
      isCollapsedSearchBarToggle,
      cmsEntriesContent,
      seoCopyContent,
      nmSeoCopyToggle,
      loadFoundIt,
      boutiquePLP,
      womenApparelCat,
      mobileFilterOptimizationAbTest,
    } = this.props;

    const { filterOptions: existingFiltersString } = getParams(this.props.location);
    let existingFilters = {};
    if (existingFiltersString) {
      try {
        existingFilters = JSON.parse(existingFiltersString);
        existingFilters['In Store'] = existingFilters['In Store']?.filter((element,index) => (index > 0 && (element !== '' && element !== null)));
      } catch (e) {
        existingFilters = {};
      }
    }
    const selectedFilters = reduce(this.props.applicableFilters, (result, filterOption) => {
      const filterKey = get(filterOption, 'filterKey');
      const selectedOptions = get(existingFilters, get(filterOption, 'filterKey'), []);
      let validSelectedOptions;
      if (filterKey === IN_STORE) {
        validSelectedOptions = selectedOptions;
      } else {
        const facets = get(filterOption, 'facets', {}) || {};
        validSelectedOptions = selectedOptions.filter((option) => facets[option]);
      }
      return Object.assign({}, result, { [get(filterOption, 'filterKey')]: validSelectedOptions });
    }, {});
    let noOfFiltersSelected = 0;

    if (seoToggle) {
      let updatedSelectedFilters = selectedFilters;
      const locationQueryString = currentNavPath?.search?.split("?")[1];
      updatedSelectedFilters = seoSelectedFilter(this.props, locationQueryString);
      updatedSelectedFilters['In Store'] = updatedSelectedFilters['In Store']?.filter((element,index) => (index > 0 && (element !== '' && element !== null)));
      noOfFiltersSelected = getSelectedFilterCount(updatedSelectedFilters);
    } else {
      noOfFiltersSelected = getSelectedFilterCountMobile(selectedFilters)
    }
    

    const productListHeaderItemsClasses = 'product-list__header__items';

    const mobileFilterButton = () => {
      let mobileFilterButton = null;
      if (this.props.applicableFilters && this.props.applicableFilters.length > 0) {
        mobileFilterButton = (
          <ConnectedMobileFacetedFilter title={noOfFiltersSelected > 0 ? `Filter (${noOfFiltersSelected})` : 'Filter'} />
        );
      }

      return mobileFilterButton;
    };

    const isNotTopOrBottom = !isTop && !isBottom;
    const isfilterTopBottom = !isTop && isBottom;
    const visualNavSortFilterFullWidth = sortOptions
      && sortOptions.length > 0;
    const searchForSelected = (categories, path) => {
      let categorySelected = '';
      if (categories.length === 1 && isEmpty(categories[0].categories)) {
        categorySelected = categories[0].id === path[path.length - 1] ? categories[0] : '';
        return categorySelected;
      }
      const currentLevelCat = categories.filter((cat) => {
        return cat.id === path[path.length - 1];
      });
      if (isEmpty(currentLevelCat)) {
        categories.forEach((category) => {
          if (!isEmpty(category.categories)) {
            categorySelected = searchForSelected(category.categories, path);
          }
        });
      } else {
        categorySelected = currentLevelCat[0].id === path[path.length - 1] ? currentLevelCat[0] : '';
        return categorySelected;
      }
      return categorySelected;
    };
    const selectedCategory = (leftNavigation, leftNavId) => {
      let category = '';
      const ids = leftNavId.split('_').length > 2 ? leftNavId.split('_').splice(2) : [];
      if (!isEmpty(leftNavigation) && ids.length > 0) {
        category = searchForSelected(leftNavigation, ids);
      }
      return category;
    };
    const filterSortClassnames =  {
      'mobile-options-container': true,
      'mobile-options-container-visual-nav': true,
      'mobile-options-container-visual-nav-full-width': visualNavSortFilterFullWidth,
      'shift-with-header': isNotTopOrBottom && isStickyHeaderActive,
      'shift-with-search': isNotTopOrBottom && isStickyHeaderActive && isSearchExposed,
    }

    let newFilterSortOn = false;
    if (filterSortToggle) {
      newFilterSortOn = true;
    }
    if (!filterSortToggle) {
      if (abtestFilterSortToggle) {
        if (abTestsFilterSortValue === 'b') {
          newFilterSortOn = true;
        } else if (abTestsFilterSortValue === 'a') {
          newFilterSortOn = false;
        }
      } else {
        newFilterSortOn = false;
      }
    }
    if (newFilterSortOn) {
      filterSortClassnames['mobile-options-container-bottom'] = true;
      filterSortClassnames['sticky-filter-sort-visual-nav'] = false;
      filterSortClassnames['mobile-options-container-bottom-visual-nav-full-width'] = true;
      filterSortClassnames['mobile-options-container-visual-nav-full-width'] = false;
    }
    const boutiquePage = (boutiqueChild || boutiqueFlag) ? false : true;

    const hideVisualNav = isMobilePhone && !boutiquePLP && !womenApparelCat;
   
    let hasVisualNavOrPromoBanner = false;
    if (nmSeoCopyToggle) {
      if (hasContentType(cmsEntriesContent, 'visualNav') && !hideVisualNav && mobileFacetButtonsAbTest) {
        hasVisualNavOrPromoBanner = true;
      } else if (checkCmsBannerPosition(cmsEntriesContent, "Top")) {
        hasVisualNavOrPromoBanner = true;
      } else if(!isNull(subTitle) && nmAwardToggle){
        hasVisualNavOrPromoBanner = true;
      }
    }

    return (
      <div className="product-list__header grid-100 tablet-grid-100 mobile-grid-100 grid-parent">
        <div className="grid-100 tablet-grid-100 mobile-grid-100">

          {
            <div className={classnames("product-list__header__container", {
              "align-header-mobile": isMobilePhone && !loadFoundIt,
              "hide-on-mobile": isMobilePhone && loadFoundIt,
            })} >
              {!newVisNavToggle && getCategoryTitle('product-list__header__title',
                boutiqueChild,
                title,
                designerName,
                isDomestic,
                customHeaderCategories,
                catId,
                subTitle,
                nmAwardToggle,
                nmSeoCopyToggle,
                !isNull(seoCopyContent) ? this.state.titleWidth : null
              )}

              {(!newVisNavToggle && nmSeoCopyToggle) && getNmSeoContent(seoCopyContent, isMobilePhone, hasVisualNavOrPromoBanner, this.setSeoWidth)}
              {
                !newVisNavToggle && (
                  <div className="product-list__header__items hide-on-desktop hide-on-tablet">
                    {renderFormattedNumber(this.props.totalItems)}
                    {' Items'}
                  </div>
                )
              }
            </div>
          }

          {
            !isMobilePhone
            && visualNavSliderToggle
            && visualNavItems
            && <VisualNavSlider data={visualNavItems} />
          }

          <div className="product-list__header__bottom hide-on-mobile">
            {sortOptions && sortOptions.length > 0
              && (
              <div className="product-list__header__sort hide-on-mobile">
                <div className="product-list__header__sort__label">
                  {`${SORT_BY_TEXT}:`}
                </div>
                <span className="product-list__header__sort__dropdown_wrapper">
                  <DropDown
                    options={sortOptions}
                    selectedValue={this.props.selectedSortBy}
                    onChange={this.onSortChange}
                    featureName="Sort By"
                    className={classnames('product-list__header__sort__dropdown', { 'rebrand_dropdown_design': isRebrandNMToggleOn })}
                    aria-label="ada-sortBy-label"
                    aria-describedby="ada-sortBy-description"
                  />
                </span>
              </div>
              )
            }
            <div className={productListHeaderItemsClasses}>
              {renderFormattedNumber(this.props.totalItems)}
              {' items'}
            </div>
            <div className="pagination-container hide-on-mobile">
              <Pagination source="PLP" />
            </div>
          </div>
        </div>
        {(!abtButtonNavToggle && boutiquePage) &&
        <div className="hide-on-desktop hide-on-tablet">
          {
            this.props.scrollMenuItems
            && this.props.isMobilePhone
            && (
              <ScrollMenu
                items={this.props.scrollMenuItems}
                className="scrollmenu"
                selectedCategory={selectedCategory(leftNavigation, leftNavId)}
              />
            )
          }
        </div>
        }
        <HeaderPosition
          componentName="control"
          controlClass="sticky-header-controls"
          isMobile={this.props.isMobilePhone}
          isStickyHeaderActive={isStickyHeaderActive}
          typeAheadExperience={typeAheadExperience}
          isSearchExposed={isSearchExposed}
          isCollapsedSearchBarToggle={isCollapsedSearchBarToggle}
        >
          {isMobilePhone && mobileFacetButtonsAbTest ?
            <div
              className={classnames('hide-on-desktop hide-on-tablet', filterSortClassnames)}
            >
              <div
                className={ classnames('mobile-grid-100 grid-parent', {
                  'grid-facet-buttons-sticky': true,
                  'mobile-filter-optimization': mobileFilterOptimizationAbTest,
                })}
              >
                <MobileFacetFilterButtons 
                  noOfFiltersSelected={noOfFiltersSelected} 
                  sortOptions={sortOptions}
                  selectedValue={this.props.selectedSortBy}
                  onSortChange={this.onSortChange}
                  component={MobileSort}
                  totalItems={renderFormattedNumber(this.props.totalItems)}
                />
              </div> 
            </div>
          : (
          <div
            className={classnames('hide-on-desktop hide-on-tablet', filterSortClassnames)}
          >
            <div
              className={ classnames('mobile-grid-100 grid-parent', {
                'grid-filter-sort-sticky': true,
              })}
            >
              <div className="mobile-grid-50">
                {mobileFilterButton()}
              </div>
              <div className="mobile-grid-50">
                  <MobileOption
                    secondary
                    title="SORT"
                    dataTestid="mobile-sort-button"
                    options={sortOptions}
                    selectedValue={this.props.selectedSortBy}
                    onClick={this.onSortChange}
                    component={MobileSort}
                    headerTitle="SORT BY:"
                  />
                </div>
            </div>
          </div>
          )}
        </HeaderPosition>
        {(isMobilePhone && mobileFacetButtonsAbTest) && (
          <div>
            <Divider />
          </div>
        )}

        { sysfacetMobileToggle && brand !== 'HC' && !isInternational && isInStoreFacetAvailable ? (
          <ConnectedCSPToggle itemsCount={renderFormattedNumber(this.props.totalItems)} />
        ) : (
          !mobileFilterOptimizationAbTest && <div className="product-list__header__items-updated hide-on-desktop hide-on-tablet">
            {renderFormattedNumber(this.props.totalItems)}
            {' Items'}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const breadcrumbs = get(state, 'navigation.breadcrumbs', '');
  const breadcrumbsForPrevTitle = modifyBreadCrumbs(breadcrumbs, get(state, 'leftNavigation.id', ''));
  const prevCategory = breadcrumbsForPrevTitle[breadcrumbsForPrevTitle.length - 2];
  const isDomestic = get(state, 'locale.countryCode', '') === 'US';

  let finalNav = null;
  const boutiqueLeftNav = (visualNav) => {
    if (!isEmpty(visualNav)) {
      for (let i = 0; i < visualNav.length; i++) {
        if (visualNav[i].selected) {
          finalNav = visualNav[i].categories != null ? visualNav[i].categories : visualNav;
          break;
        } else if (visualNav[i].categories != null) {
          const tempNav = visualNav[i].categories;
          for (let j = 0; j < tempNav.length; j++) {
            if (tempNav[j].selected) {
              finalNav = tempNav[j].categories != null ? tempNav[j].categories : tempNav;
              break;
            } else if (tempNav[j].categories != null) {
              boutiqueLeftNav(tempNav[j].categories);
              if (!isEmpty(finalNav)) {
                break;
              }
            }
          }
        }
      }
    }
    return finalNav;
  };

  return {
    currentPage: state.productListPage.products.currentPage,
    applicableFilters: state.productListPage.products.applicableFilters,
    sortOptions: state.productListPage.products.sortOptions,
    selectedSortBy: state.productListPage.selectedSortBy,
    totalItems: state.productListPage.products.total,
    title: state.productListPage.products.title,
    boutiqueChild: get(state, 'templates.templateDetails.boutiqueChild', false)
      || get(state, 'productListPage.products.boutiqueChild', false),
    designerName: get(state, 'navigation.breadcrumbs[1].name', ''),
    plpBanner: state.ecm.ecmContent.plpBanner,
    isMobilePhone: get(state, 'device.isMobilePhone', false),
    visualNavSliderToggle: state.toggles.PLP_NAV_TOP_THUMBNAILS,
    filterSortToggle: state.toggles.FILTER_SORT,
    abtestFilterSortToggle: state.toggles.ABTEST_FILTER_SORT,
    abTestsFilterSortValue: get(state, 'abTestsOpt.nmfs0001.variation', ''),
    visualNavItems: state.navigation.visualNav,
    scrollMenuItems: boutiqueLeftNav(get(state, 'leftNavigation.leftNav', null)) || get(state, 'leftNavigation.leftNav[0].categories', null) || get(state, 'leftNavigation.leftNav', null),
    prevCategory,
    isStickyHeaderActive: state.header.isStickyActive,
    isSearchExposed: state.header.isSearchExposed,
    isTop: state.header.isTop,
    isBottom: state.header.isBottom,
    navPath: get(state, 'navigation.breadcrumbPath', ''),
    breadcrumbs,
    driveToSubCat: get(state, 'templates.templateDetails.driveTosubCategory', false),
    currentNavPath: get(state, 'routing.locationBeforeTransitions', null),
    leftNavigation: get(state, 'leftNavigation.leftNav', {}),
    leftNavId: get(state, 'leftNavigation.id', ''),
    geolocatedZip: get(state, 'api.requestContext.akamaiEdgescape.zip', null),
    geolocatedStore: get(state, 'facetedLeftNav.storeListPayload[0].storeId', null),
    newVisNavToggle: get(state.toggles, 'VISUAL_NAVIGATION_NEW', false),
    sysfacetMobileToggle: get(state.toggles, 'SYSFACET_MOBILE', false),
    brand: state.brand_name?.env,
    seoToggle: get(state, 'toggles.SEO_BUCKET_ONE', false),
    isInternational: get(state, 'locale.countryCode', 'US') !== 'US',
    isDomestic,
    customHeaderCategories: get(state?.session, 'customHeaderCategoriesMap', {}),
    catId: get(state, 'templates.templateDetails', false),
    abtButtonNavToggle: get(state, 'toggles.ABTEST_BUTTON_NAV', false) && get(state, `abTestsOpt.nplp6.variation`, 'a') === 'b',
    nmAwardToggle: get(state, 'toggles.NM_AWARDS', false),
    subTitle: get(state, 'productListPage.products.subTitle', null),
    boutiqueFlag: get(state, 'utag.productListPage.page_type') === 'boutique',
    typeAheadExperience: isTypeAheadOn(state),
    mobileFacetButtonsAbTest: get(state, `abTestsOpt.${ABTEST_FACET_BUTTONS}.variation`, 'a') === 'b',
    isRebrandNMToggleOn: get(state, 'toggles.NM_REBRAND_2023', false) && isDomestic,
    isCollapsedSearchBarToggle: isCollapsedSearchBarOn(state),
    cmsEntriesContent: get(state, 'cms.entries[0].fields.l1Layouts', []),
    nmSeoCopyToggle: isDomestic && get(state, 'toggles.NM_SEO_COPY', false),
    seoCopyContent: get(state, 'productListPage.seo.content', null),
    boutiquePLP: isBoutiquePLP(state),
    womenApparelCat: isWomenApparelCat(state),
    mobileFilterOptimizationAbTest: get(state, `abTestsOpt.${ABTEST_MOBILE_FILTER_OPTIMIZATION}.variation`, 'a') === 'b',
  };
}

export default withRouter(connect(mapStateToProps, null)(ProductListHeader));
