import isEmpty from 'lodash/isEmpty';


function beginsWithNonAlpha(inputString) {
  return inputString.match('^[^A-Za-z]');
}

export function sortSelectedValuesForDesigner(validSelections) {
  if (isEmpty(validSelections)) {
    return [];
  }
  return validSelections.sort((filterOptionOne, filterOptionTwo) => {
    const lowerCaseFilterOptionOne = filterOptionOne.toLowerCase();
    const lowerCaseFilterOptionTwo = filterOptionTwo.toLowerCase();
    const filterOptionOneBeginsWithNonAlpha = beginsWithNonAlpha(lowerCaseFilterOptionOne);
    const filterOptionTwoBeginsWithNonAlpha = beginsWithNonAlpha(lowerCaseFilterOptionTwo);
    const oneOfTheFilterOptionBeginsWithANonAlpha = (
      (filterOptionOneBeginsWithNonAlpha && !filterOptionTwoBeginsWithNonAlpha)
      || (filterOptionTwoBeginsWithNonAlpha && !filterOptionOneBeginsWithNonAlpha)
    );
    const lexicalSortResult = lowerCaseFilterOptionOne < lowerCaseFilterOptionTwo ? -1 : 1;
    return oneOfTheFilterOptionBeginsWithANonAlpha ? -1 * lexicalSortResult : lexicalSortResult;
  });
}

export function sortSelectedValuesForGeneral(keys, validSelections) {
  if (isEmpty(validSelections)) {
    return [];
  }
  return validSelections.sort(
    (filterOptionOne, filterOptionTwo) => {
      return keys.indexOf(filterOptionOne) - keys.indexOf(filterOptionTwo);
    }
  );
}
