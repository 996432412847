/* eslint import/no-cycle: 0 */
import React, { useRef } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import classNames from 'classnames';
import { PAGE_ID_PLP } from 'shared/actions/actions-page';
import { concatTrackTagsObjects, getTrackingTags } from 'cms/components/utils';
import RenderContentItem from '../renderContentItem';
import './visualNav.scss';
import { ABTEST_BUTTON_NAV, ABTEST_FOUNDIT_VN_NEW} from '../../../common/actions/actions-page';
import CustomArrow from '../Product/CustomArrow';
import window from 'window-or-global';
import CustomLink from 'client/common/components/CustomLink/customLink';

const CN = 'visual-nav';

export function ConnectedVisualNav(props) {
  const {
    pageId,
    cmsContentItem: {
      fields: { slides, trackingTags: ownTrackTags = {} },
    },
    trackTags: propsTrackTags = {},
    isMobilePhone,
    isTablet,
    abtButtonNavToggle,
    boutiqueChild,
    boutiqueFlag,
    foundItData,
    founditVNToggle,
  } = props;
  const trackTags = concatTrackTagsObjects(propsTrackTags, getTrackingTags(ownTrackTags));
  const founditProducts = foundItData['Main Links'] || [];

  let initalSlidesToShow = 6;
  if (isTablet) {
    initalSlidesToShow = 4;
  } else if (isMobilePhone) {
    initalSlidesToShow = 2.5;
  }

  const sliderRef = useRef(null);

  const getMatchingTileIndex = () => {
    const pageURL = window?.location?.href || '';
    const currentTileIndex = founditProducts.findIndex((item) => {
      return item.DestinationUrl === pageURL;
    })

    return currentTileIndex;
  }

  const getResponsiveFocusIndex = (index) => {
    const productsCount = founditProducts.length;
    if(productsCount > parseInt(initalSlidesToShow)){
      const unEvenTilesCount = productsCount - index;
      if(unEvenTilesCount < parseInt(initalSlidesToShow)){
        return index - parseInt(initalSlidesToShow) + unEvenTilesCount;
      } else return index;
    } else return 0;
  }

  const getInitialFocusIndex = () => {
    const tileMatchIndex = getMatchingTileIndex();
    if(tileMatchIndex > 0){
      const tileFocusIndex = getResponsiveFocusIndex(tileMatchIndex);
      return tileFocusIndex;
    } return 0;
  }

  const settings = {
    arrows: true,
    className: classNames(CN, 'updated-style'),
    infinite: false,
    slidesToShow: initalSlidesToShow,
    swipeToSlide: true,
    draggable: true,
    nextArrow: <CustomArrow ariaLabel="next" />,
    prevArrow: <CustomArrow ariaLabel="previous" />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          draggable: false,
        },
      },
    ],
  };

  if(founditVNToggle && founditProducts.length > 0 && sliderRef?.current){
    const focusIndex = getInitialFocusIndex();
    if(focusIndex > 0){
      sliderRef.current.slickGoTo(focusIndex, true);
    }
  }

  if (isMobilePhone) {
    settings.slidesToScroll = 2;
    settings.arrows = false;
    settings.swipeToSlide = false;
  }

  const parentType = 'NewVisualNav';
  const boutiquePage = (boutiqueChild || boutiqueFlag) ? false : true;
  const buttonNavNonBoutiquePage = abtButtonNavToggle && boutiquePage;

  return (
    (pageId === PAGE_ID_PLP || typeof window !== 'undefined') &&
    <div className={classNames({ 'cms-btnav': buttonNavNonBoutiquePage })}>
      <div className={classNames(buttonNavNonBoutiquePage ? 'cmscontent-buttonNav' : 'cmscontent-visual-rendering', founditVNToggle && 'foundItVN')}>
          {(buttonNavNonBoutiquePage ? (
            slides.map((slide, idx) => (
              <RenderContentItem
                trackTags={trackTags}
                cmsContentItem={slide}
                key={slide.sys.id}
                parentType={parentType}
                neverLazyload={idx < settings.slidesToShow}
              />
            ))
          ) :
          (founditVNToggle && founditProducts.length > 0) ? <Slider {...settings} ref={sliderRef}>
              {
                founditProducts.map((slide, index) => (
                  <div key={index} class="imagewithtext image-top_desktop image-top_tablet">
                      <div class="imagewithtext__image-top_desktop imagewithtext__image-top_tablet">
                          <div class="image-asset-wrapper">
                            <CustomLink url={slide.DestinationUrl} className="image-link">
                              <img src={slide.ImageUrl} alt={slide.AnchorText} title={slide.AnchorText} class="image-asset" />
                            </CustomLink>
                          </div>
                      </div>
                      <div class="imagewithtext__text-middle_desktop imagewithtext__text-middle_tablet iwt-text visual-nav">
                          <div class="rich-text list romie-regular">
                              <p>
                                <CustomLink url={slide.DestinationUrl} >{slide.AnchorText}</CustomLink>
                              </p>
                          </div>
                      </div>
                  </div>
                  )
                )
              }
            </Slider>
          :
            <Slider {...settings}>
              { slides.map((slide, idx) => (
                  <RenderContentItem
                    trackTags={trackTags}
                    cmsContentItem={slide}
                    key={slide.sys.id}
                    parentType={parentType}
                    neverLazyload={idx < settings.slidesToShow}
                  />
                ))
              }
            </Slider>
          )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  pageId: get(state, 'page.pageId', ''),
  isMobilePhone: get(state, 'device.isMobilePhone', false),
  isTablet: get(state, 'device.isTablet', false),
  abtButtonNavToggle: get(state, 'toggles.ABTEST_BUTTON_NAV', false) && get(state, `abTestsOpt.${ABTEST_BUTTON_NAV}.variation`, 'a') === 'b',
  boutiqueChild: get(state, 'templates.templateDetails.boutiqueChild', false)
  || get(state, 'productListPage.products.boutiqueChild', false),
  boutiqueFlag: get(state, 'utag.productListPage.page_type') === 'boutique',
  foundItData: get(state, 'productListPage.visualNav.Links', []),
  founditVNToggle: get(state, 'toggles.FOUNDIT_VN', false) || (get(state, 'toggles.ABTEST_FOUNDIT_VN_NEW', false) && get(state, `abTestsOpt.${ABTEST_FOUNDIT_VN_NEW}.variation`, 'a') === 'b'),
});

export default connect(mapStateToProps)(ConnectedVisualNav);
