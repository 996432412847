import React from 'react';

import './CategoryFilter.scss';
import CustomLink from 'client/common/components/CustomLink/customLink';

const CategoryFilter = ({ categories, selectedCatId }) => {
  return (
    <nav className="category-filters">
      <h1>Designers by Category</h1>
      <ul>
        {
          categories.map((item, idx) => {
            return item.id === selectedCatId
              ? (
                <li key={idx}>
                  <p><CustomLink aria-current="page" className="selected-category" url={item.url}>{item.name}</CustomLink></p>
                </li>
              )
              : (
                <li key={idx}>
                  <p><CustomLink url={item.url}>{item.name}</CustomLink></p>
                </li>
              );
          })
        }
      </ul>
    </nav>
  );
};

export default CategoryFilter;
