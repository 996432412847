import React, { Fragment } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { parseUrlId } from 'client/utilities/utilities-url';
import { getParams } from 'client-utils/utilities-router';
import ECMGet from 'ecm/components/EcmGet';
import { ECMSlots } from 'ecm/constants';
import Seo from 'plp/components/ProductListPage/components/Seo/seo';
import ConnectedLeftNavigation from 'storefront/components/LeftNavigation/leftNavigation';
import ConnectedBreadcrumb from 'storefront/components/Navigation/Breadcrumb/breadcrumb';
import './Entry.scss';
import { renderCMSContent, cmsContentExists } from '../../utilities/utilities-cms';
import CustomLink from 'client/common/components/CustomLink/customLink';

const Entry = (props) => {
  const { routeParams = {}, renderCmsInTemplate } = props;
  let categoryId;
  switch (props.plpSeoUrlToggle) {
    case true:
      categoryId = routeParams.categoryInfo
        ? parseUrlId(routeParams.categoryInfo)
        : get(routeParams, 'categoryId', '').split('_').slice(0, 1).toString();
      break;
    case false:
      categoryId = get(routeParams, 'categoryId', '').split('_').slice(0, 1).toString();
      break;
    default:
      categoryId = get(routeParams, 'categoryId', '').split('_').slice(0, 1).toString();
      break;
  }
  const { source } = getParams(props.location);
  const isEcmAvailable = !isEmpty(get(props.templateDetails, 'templateAttributes.ecmContent', false));
  const areMobileSubcatsAvailable = !isEmpty(get(props.templateDetails, 'templateAttributes.navigation.mobileNavigation', false));
  const isBreadcrumbsAvailable = Boolean(get(props.templateDetails, 'templateAttributes.navigation.breadcrumbs', false));
  const isLeftNavAvailable = Boolean(get(props.templateDetails, 'templateAttributes.navigation.leftNavigation', false));
  const isSeoAvailable = !isEmpty(get(props.templateDetails, 'templateAttributes.dynamicContent.seoContent', {}));

  const renderEcm = isEcmAvailable && (
    ""
  );

  const renderContent = cmsContentExists(renderCmsInTemplate) ? renderCMSContent(renderCmsInTemplate, 'Main') : renderEcm;
  const renderBanner = renderCMSContent(renderCmsInTemplate, 'Top');

  const renderLeftNavigation = (
    <ConnectedLeftNavigation
      routeParams={{ ...props.routeParams }}
      navpath={props.location.navpath}
      source={source}
    />
  );

  const renderBreadcrumbs = (
    <ConnectedBreadcrumb
      defaultCategoryId={categoryId}
      source={source}
      isEntry={true}
    />
  );

  const renderSeoContent = isSeoAvailable
    ? (
      <Seo
        title={get(props.templateDetails, 'templateAttributes.dynamicContent.seoContent.title', '')}
        content={get(props.templateDetails, 'templateAttributes.dynamicContent.seoContent.description', '')}
        margin={46}
      />
    )
    : false;
  const breadcrumbsClass = classNames({
    SC__breadcrumbs_empty: !isBreadcrumbsAvailable,
  });

  const mobileSubCatDisplay = areMobileSubcatsAvailable
    ? props.templateDetails.templateAttributes.navigation.mobileNavigation.map((curr) => {
      return (
        <Fragment>
          <CustomLink className="Entry__mobile_subcat_flex_container" url={curr.url}>
            <h2 className="Entry__mobile_subcat_dispaly_name">{curr.name.toUpperCase()}</h2>
            <i />
          </CustomLink>
          <span className="Entry__mobile_subcat_separator" />
        </Fragment>
      );
    })
    : false;

  return (
    <Fragment>
      <div className="grid-parent">
        <div className={breadcrumbsClass}>
          {isBreadcrumbsAvailable && renderBreadcrumbs}
        </div>
        <div className="grid-100" id="top-banner">
          {renderBanner}
        </div>
      </div>
      <div className="grid-parent">
        {isLeftNavAvailable && renderLeftNavigation}
        <div className="SC__dynamic_container grid-80 tablet-grid-80 mobile-grid-100 grid-parent">
          <div className="grid-100 Entry__ecm_container">
            <div>
              {renderContent}
            </div>
          </div>
          <div className="mobile-grid-100">
            {isSeoAvailable && renderSeoContent}
          </div>
        </div>
        <div className="Entry__mobile_subcat_container grid-parent hide-on-desktop hide-on-tablet">
          <div className="mobile-grid-100">
            <span className="Entry__mobile_subcat_separator first_mobile_separator" />
            {mobileSubCatDisplay}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Entry;
