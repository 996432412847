import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classnames from 'classnames';
import {
  AccountMenuItems,
  sanitizeUserName,
} from 'storefront/components/AccountMenuItems/AccountMenuItems';
import CustomLink from 'client/common/components/CustomLink/customLink';

const defaultMenuItem = 'mobilenav__profilemenu-link';

const userSpecificAccount = (
  firstName,
  navLinkClass,
  shouldShowSpecialOffersLink,
  isDomestic
) => {
  const usersFirstName = sanitizeUserName(firstName);
  return (
    <ul className={classnames({ 'bordered-account-info': navLinkClass.length > 0 })}>
      <li
        className={classnames('mobilenav__menuitem',
          { 'row-height-adjust': navLinkClass.length > 0 })}
      >
        <span id="user-account-name">{`${usersFirstName}'s Account`}</span>
      </li>
      <AccountMenuItems
        authenticatedUserName={firstName}
        mobileClass={navLinkClass.length > 0 ? navLinkClass : defaultMenuItem}
        ulClass="mobilenav__profilemenu"
        shouldShowSpecialOffersLink={shouldShowSpecialOffersLink}
        isDomestic={isDomestic}
        cleanHeaderNav={navLinkClass.length > 0}
      />
    </ul>
  );
};

const signInRegister = () => {
  return (
    <CustomLink className="profile__signin-link" url={NMConfig.ACCOUNT_PAGE_URL}>
      {'Sign In / Register'}
    </CustomLink>
  );
};

const AccountMenu = (props) => {
  const {
    name, navLinkClass = '', shouldShowSpecialOffersLink, isDomestic,
  } = props;
  if (name) {
    return userSpecificAccount(
      name,
      navLinkClass,
      shouldShowSpecialOffersLink,
      isDomestic,
    );
  }
  return signInRegister();
};

const mapStateToProps = (state) => {
  const isDomestic = get(state, 'locale.countryCode') === 'US'
    || get(state, 'locale.countryCode') === 'Unites_States';
  const shouldShowSpecialOffersLink = get(state, 'toggles.WKY_LINK_TO_SPECIAL_OFFERS_PAGE', false);
  return {
    name: state.user.name,
    isDomestic,
    shouldShowSpecialOffersLink,
  };
};

export default connect(mapStateToProps)(AccountMenu);
