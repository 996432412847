import React, { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import Toggle from 'shared/components/Toggle/toggle';
import buildArrayOfCategoriesByColumnBreakFlg from '../../drawerUtility';
import './featuredDesigners.scss';
import CustomLink from 'client/common/components/CustomLink/customLink';

const BuildColumnWithCategories = (categories) => {
  return (categories.map((category, index) => {
    const categoryRedText = category.attributes && category.attributes.redTextFlag ? 'red-text' : '';
    const anchorProps = categoryRedText ? { className: categoryRedText } : {};
    return (
      <li key={index}>
        <CustomLink url={category.url} {...anchorProps}>
          {category.name}
          {!isEmpty(category.attributes && category.attributes.boutiqueTextAdornments)
            ? <span className="boutique-text-adornments">{category.attributes.boutiqueTextAdornments}</span>
            : false
          }
        </CustomLink>
      </li>
    );
  }));
};

const BuildColumns = (dataToDisplay) => {
  const arrayOfCategoriesGrouped = buildArrayOfCategoriesByColumnBreakFlg(dataToDisplay);
  return arrayOfCategoriesGrouped
    .map((categories, index) => (
      <div key={index} className="grid-33 tablet-grid-33 featured-designers-column">
        <ul>
          {BuildColumnWithCategories(categories)}
        </ul>
      </div>
    ));
};

const FeaturedDesigners = ({ dataToDisplay, titleToDisplay, getPromoContent }) => (
  <div className="grid-75 tablet-grid-70 grid-parent right-panel">
    <Toggle
      feature="SILO_DRAWER_FULL_WIDTH"
      fallback={(
        <Fragment>
          {getPromoContent && getPromoContent()}
          <div className="grid-100 featured-designers-section">
            <h6 className="featured-heading">
              {titleToDisplay}
            </h6>
          </div>
          {BuildColumns(dataToDisplay)}
        </Fragment>
      )}
    >
      <div className="grid-100 featured-designers-section">
        <h6 className="featured-heading">
          {titleToDisplay}
        </h6>
      </div>
      {BuildColumns(dataToDisplay)}
      <div className="grid-25 tablet-grid-25 featured-designers-column">
        {getPromoContent && getPromoContent()}
      </div>
    </Toggle>
  </div>
);

export default FeaturedDesigners;
