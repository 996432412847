import React from 'react';
import classnames from 'classnames';
import { featuredDesigners, featuredDesignersMen, featuredDesignersWomen } from './FeaturedDesignersData';
import './MenuItem.scss';
import CustomLink from 'client/common/components/CustomLink/customLink';

export const FeaturedDesignersMenuItem = ({
  selected, genderToggle, genderAbTestGroup, gender
}) => {
  let featuredDesignersList = [];

  if (genderToggle || genderAbTestGroup) {
    if (gender === 'M') {
      featuredDesignersList = featuredDesignersMen;
    } else if (gender === 'W') {
      featuredDesignersList = featuredDesignersWomen;
    } else {
      featuredDesignersList = featuredDesigners;
    }
  } else {
    featuredDesignersList = featuredDesigners;
  }

  return [
    <li
      className={classnames({
        mobilenav__menuitem: true,
        'designers-title__highlight': selected === 'cat000730',
      })}
      id="designersAZMobile"
      key="designerAZMobile"
      order={0}
    >
      <CustomLink url="/c/designers-cat000730?navpath=cat000000_cat000730" role="button">
        <div role="button">
            Designers A To Z
        </div>
      </CustomLink>
    </li>,
    <li className="mobilenav__menuitem designer" id="featuredDesignersHeading" key="fdMobile">
      <span role="button">
            Featured Designers
      </span>
    </li>,
    featuredDesignersList.map((featuredDesigner, idx) => {
      const higlightDesignerName = featuredDesigner.catId === selected;

      return (
        <li
          className={classnames({
            'mobilenav__menuitem designer': true,
            'highlighted-designer': higlightDesignerName,
          })}
          id={featuredDesigner.id}
          key={featuredDesigner.id}
          order={idx + 1}
        >
          <CustomLink url={featuredDesigner.url} role="button">
            {featuredDesigner.name}
          </CustomLink>
        </li>
      );
    }),
  ];
};
